import { Box, Button, Card, CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { PostData } from "../home/healhinfor/PostData";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import URL from "../../services/api_url";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import { KeyboardArrowLeft, KeyboardArrowRight, LensOutlined, LensRounded } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div
      className="unique-button-group-container"
      style={{ position: "absolute", top: "0", right: "0" }}
    >
      <IconButton
        aria-label="arrow-left"
        className={`carousel-arrow-button ${currentSlide === 0 ? "disable" : ""
          }`}
        onClick={() => previous()}
      >
        <KeyboardArrowLeft fontSize="inherit" />
      </IconButton>
      <IconButton
        aria-label="arrow-right"
        className="carousel-arrow-button"
        onClick={() => next()}
      >
        <KeyboardArrowRight fontSize="inherit" />
      </IconButton>
    </div>
  );
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  return (
    <li
      style={{
        cursor: "pointer",
        color: active ? "#134265" : "#fff",
        marginTop: "10px",
      }}
      onClick={() => onClick()}
    >
      {active ? (
        <LensRounded style={{ fontSize: "14px" }} />
      ) : (
        <LensOutlined style={{ fontSize: "14px", color: "#000" }} />
      )}
    </li>
  );
};
const AllPosts = ({ pageIds }) => {
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
  const navigate = useNavigate()
  const pageId = selectedbranch?.pageId ? selectedbranch?.pageId : pageIds

  useEffect(() => {
    postAPIS();
  }, [pageId]);
  const postAPIS = async (key) => {
    // console.log("hello");
    if (pageId) {
      setLoading(true);
      await axios.get(`${URL.feed}feed/getpostbypageid/${pageId}?limit=25&offset=0`)
        .then((res) => {
          setPosts(res.data?.results || []);
          // console.log(res.data.results, "555");
        })
        .catch((error) => console.log(error, "6666666666"))
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      {/* <div style={{ position: "relative" }}>
        <div style={{ display: "flex" }}> */}
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true}
        keyBoardControl={true}
        containerClass="carousel-container-3"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside={true}
        customDot={<CustomDot />}
        arrows={false}
      >
        {loading ? (
          <CircularProgress sx={{ display: "block", mx: "auto", mt: 3 }} />
        ) : (
          // Ensure posts is not undefined and it has length
          posts && posts.length > 0 ? (
            posts.map((post, index) => (
              <PostData feedData={post} key={index} />
            ))
          ) : (
            <div className="text-center font-medium text-lg"></div>
          )
        )}
      </Carousel>

      {/* </div>
      </div> */}
      {/* <Grid container gap={2} sx={{ mt: "10px" }}>
        {/* {(console.log(posts), "vvv")} */}


      {/* {posts.map((post, val) => (
          <Grid item xs={12} sm={5.9} md={3.9}>
          
            <PostData feedData={post} key={val} />
         
          </Grid>
        ))}  */}
      {/* </Grid> * /} */}

      {posts && posts.length > 0 && (
        < Box sx={{ mt: "10px", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "transparent",
              color: "black",
              boxShadow: "none",
              textTransform: "capitalize",
              marginX: "auto",
              border: "1px solid #A3A3A3",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50px",
              gap: 1,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            // onClick={() => { navigate(selectedbranch?.name ? `homeposts` : '/homeposts', { state: { posts } }) }}
            onClick={() => navigate(selectedbranch?.name ? `/${selectedbranch?.name}/homeposts` : `/homeposts`, { state: { posts } })}
          >
            Show All Posts
            <ArrowRightAltIcon />
          </Button>
        </Box >
      )
      }
    </Box >
  );
};

export default AllPosts;
