import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import URL from '../../../services/api_url'
import axios from 'axios'
import { Avatar, Box, CardMedia, Grid, IconButton, Typography } from '@mui/material'
import { timeSince } from '../../atoms/timeSince'
import moment from 'moment'
import medxpertsIcon from "../../../assets/images/like-icon.svg";
import ShareImg from "../../../assets/images/Share 1.svg";
import { Public } from '@mui/icons-material'
import Text from './Text'
import Media from './Media'
import Video from './Video'
import ArticleView from './ArticleView'
import ViewEvents from './ViewEvents'
import FeedMedia from '../feedPost/feedMedia'
import FeedArticle from '../feedPost/feedArticle'
import FeedEvent from '../feedPost/feedEvent'


function FeedPost() {
  const { id } = useParams()
  const [item, setItem] = useState({})
  const fetchPost = async () => {
    if (id) {
      try {
        const res = await axios.get(`${URL.feed}feed/postwithouttoken/${id}`)
        setItem(res?.data)
      } catch (error) {

      }
    }

  }


  useEffect(() => {
    fetchPost()
  }, [id])


  return (
    <Box sx={{ p: { xs: '0px', sm: '0px', md: '10px 90px', lg: '10px 90px' } }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Box
            sx={{
              p: '6px',
              bgcolor: '#FFFFFF',
              border: "1px solid #e5e5dc",
              borderRadius: "10px",
              gap: 1,
              mt: 1,
              m: { xs: '15px 0px ', sm: '15px 4px', md: "15px" },
              // height: "546px",
            }}
          // onClick={() => handleNavigatePost(item?.id)}

          >

            {/* <Typography
            sx={{
              textOverflow: "ellipsis",
              wordWrap: "break-word",
              fontWeight: 500,
              fontSize: 14,
              p: "0px 10px",
            }}
          >
            {item?.description ? (
              <>
                {expanded
                  ? item.description
                  : `${item.description.slice(0, 114)}`}
                {item.description.length > 95 ? (
                  <Typography
                    component={"span"}
                    onClick={handleExpandClick}
                    sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                  >
                    {expanded ? "...see less" : "...see more"}{" "}
                  </Typography>
                ) : (
                  ""
                )}
              </>
            ) : (
              "Hi"
            )}
          </Typography> */}
            {/* <CardContent> */}
            <Box >
              {
                item.postType === "TEXT" ? (
                  <Text docFeed={item} />
                ) : item.postType === "IMAGE" ? (
                  <FeedMedia docFeed={item} />
                ) : item.postType === "VIDEO" ? (
                  <Video docFeed={item} />
                ) : item.postType === "ARTICLE" ? (
                  <FeedArticle docFeed={item} />
                ) : item.postType === "EVENT" ? (
                  <FeedEvent docFeed={item} />
                ) : null}
              {/* </CardContent> */}
            </Box>

          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>

          <Grid
            item
            container
            // spacing={2}
            sx={{
              p: "7px 5px",
              height: '100px'
            }}

          >
            <Grid item xs={2.1} sm={2.3} md={2.2} lg={2}>
              {item?.pageData?.profilePic?.length > 0 ||
                item?.userData?.profilePic?.length > 0 ? (

                <CardMedia
                  sx={{
                    width: { sm: 54, md: 54, lg: 62 },
                    height: { sm: 54, md: 54, lg: 62 },
                    border: '3px solid #fff',
                    padding: "3px",
                    borderRadius: '12px',
                    background: '#C6C6C6'
                  }}
                  className="shadow-md"
                  component="img"
                  alt="doctor img"
                  image={item.pageData?.profilePic
                    ? item.pageData.profilePic
                    : item.userData?.profilePic}
                />
              ) : (
                <Avatar
                  sx={{
                    borderRadius: "7px!important",
                    // border: "1px solid lightgrey",
                    width: { sm: 55, md: 65 },
                    height: { sm: 55, md: 65 },
                  }}
                  src={medxpertsIcon}
                />
              )}
            </Grid>

            <Grid item xs={7.9} sm={7.7} md={8.3} lg={8.8}>
              {item?.pageData?.fullName?.length > 0 ||
                item?.userData?.fullName?.length > 0 ? (
                <Typography
                  sx={{
                    fontSize: { xs: 14, sm: 14, md: 14 },
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  {(item.pageData?.fullName || item.userData?.fullName || "")
                    .charAt(0)
                    .toUpperCase() +
                    (
                      item.pageData?.fullName ||
                      item.userData?.fullName ||
                      ""
                    ).slice(1)}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
              )}

              {item?.pageData?.caption?.length > 0 ||
                item?.userData?.caption?.length > 0 ? (
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 12, md: 12 },
                  }}
                >
                  {item.pageData?.caption || item.userData?.caption}
                </Typography>
              ) : null}

              <Typography color="black">
                <Typography
                  sx={{
                    fontSize: { xs: 12, sm: 12, md: 12 },
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Public sx={{ fontSize: "14px", color: "#336ECC" }} />
                  {`${timeSince(moment.utc(item.createDate).local())}`} ago
                </Typography>
                &nbsp;
              </Typography>
            </Grid>

            <Grid item xs={2} sm={2} md={1}>
              <IconButton>
                <img src={ShareImg} alt="share" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid></Box >
  )
}

export default FeedPost