import React, { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { BsChatRightTextFill } from "react-icons/bs";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import "./chat.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "axios";
// import { ActiveChat } from "./ActiveChat";
import { ActiveChat } from "./activeChat";
import { localstore } from "../localstore/localstore";
import { CustomTextField } from "../atoms/TextField";

export default function Chat() {
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [messageHistory, setMessageHistory] = useState([]);
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [visitorId, setVisitorId] = useState("");
    const [participantId, setParticipantId] = useState("");
    const [socketUrl, setSocketUrl] = useState("");
    const [userListOpen, setUserListopen] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [latLong, setLatLong] = useState(null);
    const usertoken = localstore.getToken()
    let ptId = localStorage.getItem("participantId");

    const { getWebSocket, readyState, sendJsonMessage } = useWebSocket(socketUrl, {
        onOpen: () => {
            // console.log("Connected!");
        },
        onClose: () => {
            // console.log("Disconnected!");
        },
        onMessage: (e) => {
            const data = JSON.parse(e.data);
            switch (data.type) {
                case "welcome_message":
                    setWelcomeMessage(data.message);
                    break;
                case "chat.message":
                    setMessageHistory((prev) => prev.concat(data));
                    break;
                default:
                    // console.log("Unknown message type!");
                    break;
            }
        },
        shouldReconnect: (closeEvent) => true,
    });

    const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Open",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];

    useEffect(() => {
        return () => {
            const socket = getWebSocket();
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
        };
    }, [getWebSocket]);

    useEffect(() => {
        if (participantId) {
            axios.get(`${process.env.REACT_APP_CHAT_URL}GetParticipant/${participantId}`).then((res) => {
                if (res?.status === 204) {
                    setUserDetails({});
                } else {
                    setUserDetails(res?.data);
                }
            }).catch((err) => console.log(err));
        }
    }, [participantId]);

    useEffect(() => {
        if (localstore.getToken()) {
            axios.get(`${process.env.REACT_APP_CHAT_URL}GetParticipantIds?userId=${localstore.getUserId()}`, {
                headers: {
                    "Authorization": `Bearer ${usertoken}`
                }
            }).then((res) => {
                if (res?.data?.length === 0) {
                    const data = {
                        "name": localstore.getUsername(),
                        "phone": '',
                        "email": ""
                    };
                    axios.post(`createNewParticipant`, data).then((res) => {
                        setUserDetails(res?.data);
                        localStorage.setItem("participantId", res?.data?.ParticipantId);
                        setParticipantId(res?.data?.ParticipantId);
                        localStorage.setItem("roomId", res?.data?.roomid);
                    }).catch((err) => console.log(err));
                } else {
                    setUserDetails(res?.data?.[0]);
                    localStorage.setItem("participantId", res?.data?.[0]?.Participant?.participentId);
                    setParticipantId(res?.data?.[0]?.Participant?.participentId);
                    localStorage.setItem("roomId", res?.data?.[0]?.roomid);
                }
            }).catch((err) => console.log(err));
        }
    }, [localstore.getToken()]);


    const handleOnNearestLocation = async () => {
        // setLoading(true); // Start loading

        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const { latitude, longitude } = position.coords;
            const latLongString = `${latitude},${longitude}`;
            setLatLong(latLongString)
        } catch (error) {
            console.error('Error fetching geolocation data:', error);

        } finally {
            console.log(false); // End loading
        }
    }

    // useEffect(() => {
    //     const fetchFingerprint = async () => {
    //         const fp = await FingerprintJS.load();
    //         const result = await fp.get();
    //         return result.visitorId;
    //     };

    //     const init = async () => {
    //         let fingerprintId;
    //         setParticipantId(ptId);
    //         if (localStorage.getItem("visitorId")) {
    //             fingerprintId = localStorage.getItem("visitorId");
    //         } else {
    //             const fingerprint = await fetchFingerprint();
    //             fingerprintId = fingerprint;
    //             localStorage.setItem("visitorId", fingerprint);
    //         }
    //         setVisitorId(fingerprintId);
    //         if (!ptId) return;
    //         let surl = usertoken
    //             ? `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?patient=${usertoken}&visitorId=${fingerprintId}&participantId=${ptId}`
    //             : `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?participantId=${ptId}&visitorId=${fingerprintId}${latLong ? `&latLong=${latLong}` : ''}`;
    //         setSocketUrl(surl);
    //     };

    //     // if (!visitorId || !participantId) {
    //     init();
    //     // }
    // }, [visitorId, ptId]);
    useEffect(() => {
        const fetchFingerprint = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            return result.visitorId;
        };

        const init = async () => {
            let fingerprintId;
            setParticipantId(ptId);
            if (localStorage.getItem("visitorId")) {
                fingerprintId = localStorage.getItem("visitorId");
            } else {
                const fingerprint = await fetchFingerprint();
                fingerprintId = fingerprint;
                localStorage.setItem("visitorId", fingerprint);
            }
            setVisitorId(fingerprintId);
        };

        if (!visitorId || !ptId) {
            init();
        }
    }, [ptId, visitorId]);

    useEffect(() => {
        if (visitorId && ptId) {
            let surl = usertoken
                ? `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?patient=${usertoken}&visitorId=${visitorId}&participantId=${ptId}`
                : `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?participantId=${ptId}&visitorId=${visitorId}${latLong ? `&latLong=${latLong}` : ''}`;
            setSocketUrl(surl);
        }
    }, [visitorId, ptId, latLong]);


    useEffect(() => {
        if (socketUrl) {
            getWebSocket();
        }
    }, [socketUrl]);

    function handleChangeMessage(e) {
        setMessage(e.target.value);
    }

    function handleChangeName(e) {
        setName(e.target.value);
    }

    function handleChangePhone(e) {
        setPhone(e.target.value?.replace(/\D/g, "")?.slice(0, 10));
    }

    console.log(latLong, "latlong")
    useEffect(() => {
        if (localStorage?.getItem('roomId')) {
            fetchMessages();
        }
        handleOnNearestLocation()
    }, []);

    const updateUserInfo = async () => {
        const data = {
            "name": name,
            "phone": phone,
            "email": ""
        };
        try {
            const res = await axios.post(`${process.env.REACT_APP_CHAT_URL}createNewParticipant`, data);
            setUserDetails(res?.data);
            localStorage.setItem("participantId", res?.data?.participentId);
            localStorage.setItem("roomId", res?.data?.roomid);
            return res?.data;
        } catch (err) {
            console.log(err);
        }
    };

    const fetchMessages = () => {
        axios.get(`${process.env.REACT_APP_CHAT_URL}getRoomConversations/${localStorage?.getItem('roomId')}`, {

        }).then((res) => {
            setMessageHistory(res?.data);
        }).catch((err) => console.log(err));
    };

    const handleSubmit = async () => {
        if (!name.trim()) {
            alert("Please enter your name.");
            return;
        }

        if (!phone.trim() || !/^\d{10}$/.test(phone)) {
            alert("Please enter a valid 10-digit phone number.");
            return;
        }

        const userInfo = await updateUserInfo();
        if (userInfo) {
            const ptId = localStorage.getItem("participantId");
            if (visitorId && ptId) {
                const surl = localstore.getToken()
                    ? `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?patient=${localstore.getToken()}&visitorId=${visitorId}&participantId=${ptId}`
                    : `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?participantId=${ptId}&visitorId=${visitorId}${latLong ? `&latLong=${latLong}` : ''}`;
                setSocketUrl(surl);
            }

            // sendJsonMessage({
            //     "roomid": localStorage.getItem('roomId'),
            //     // "content": message,
            //     "attachment": "",
            //     "urls": "",
            //     "subject": "help"    
            // });

            setName("");
            setMessage("");
            setPhone("");
        }
    };
    console.log(userDetails, "userDetails")
    return (
        <>
            {!userListOpen ? (
                <div
                    // className="message-box"
                    className="mt-1"
                // style={{ padding: "5px 20px" }}
                >
                    <IconButton
                        sx={{
                            backgroundColor: '#E44190',
                            // color: '#FFFFFF',
                            width: 56,
                            height: 56,
                            '&:hover': {
                                backgroundColor: '#E44190',
                            },
                            // mt: 1
                        }}
                        onClick={() => setUserListopen(!userListOpen)}
                    >
                        <BsChatRightTextFill style={{ color: '#FFFFFF' }} className="text-2xl" />
                    </IconButton>
                    {/* <Button
                        variant="contained"
                        size="large"
                        // sx={{ textTransform: "capitalize", fontSize: "16px", backgroundColor: '#E44190' }}
                        onClick={() => setUserListopen(!userListOpen)}
                    > */}
                    {/* </Button> */}
                </div>
            ) : (
                <div
                    className="drop-shadow-lg message-box mt-3"
                    style={{
                        backgroundColor: userDetails?.Participant?.participentId ? '' : "#fdf3f7",
                        width: "320px",
                        height: `${window.innerHeight - 100}px`,
                        borderTopLeftRadius: '20px', borderTopRightRadius: '20px',

                    }}
                >
                    <nav className="flex justify-between" style={{ backgroundColor: '#E44190', color: '#FFFFFF', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', padding: '10px 20px' }}>
                        <div >
                            <div className="text-xl">Paramitha Hospitals</div>
                            {/* <div style={{ fontSize: '10px' }}>The WebSocket is currently {connectionStatus}</div> */}
                        </div>
                        <IconButton onClick={() => { setUserListopen(!userListOpen) }} >
                            <AiOutlineCloseCircle className="text-[#FFFFFF] text-xl" />
                        </IconButton>
                    </nav>
                    {userDetails?.Participant?.participentId ?
                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <ActiveChat sendJsonMessage={sendJsonMessage} messageHistory={messageHistory} />
                        </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px', gap: '35px', justifyContent: 'center', padding: '10px 20px' }} className="pt-2">
                            {/* <input
                                name="name"
                                placeholder="Name"
                                onChange={handleChangeName}
                                value={name}
                                className="shadow-sm sm:text-sm border-gray-300 bg-gray-100 rounded-md px-3 py-1"
                            /> */}
                            <CustomTextField type="text" name="name" placeholder="Name" value={name}
                                backgroundColor="rgb(243, 244, 246)" borderRadius="7px" onChange={handleChangeName}
                            />
                            {/* <CustomTextField type="text" name="phone" placeholder="Enter your Phone Number" value={phone}
                                backgroundColor="rgb(243, 244, 246)" borderRadius="7px" onChange={handleChangePhone}
                            /> */}
                            <TextField
                                fullWidth
                                size="small"
                                type={'text'}
                                value={phone}
                                onChange={handleChangePhone}
                                placeholder="Enter Phone Number"
                                sx={{
                                    width: '100%', mt: "5px",
                                    ".MuiInputBase-root": {
                                        borderRadius: '7px',
                                        backgroundColor: "rgb(243, 244, 246)"
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#59D8FA',
                                        },
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +91
                                        </InputAdornment>
                                    )
                                }}
                            />

                            {/* <input
                                name="phone"
                                placeholder="Enter Your Phone"
                                onChange={handleChangePhone}
                                type="number"
                                value={phone}
                                className="shadow-sm sm:text-sm border-gray-300 bg-gray-100 rounded-md px-3 py-1"
                            /> */}
                            {/* <textarea
                                name="message"
                                placeholder=" Write your first Message"
                                onChange={handleChangeMessage}
                                value={message}
                                rows={4}
                                className="shadow-sm sm:text-sm border-gray-300 bg-gray-100 rounded-md px-3 py-1"
                                style={{ color: 'black' }}
                            /> */}

                            {/* <TextField
                                name="message"
                                placeholder="Write your first message"
                                onChange={handleChangeMessage}
                                value={message}
                                multiline
                                rows={4}
                                variant="outlined" // or "filled", "standard" based on your preference
                                fullWidth
                                InputProps={{
                                    style: {
                                        backgroundColor: '#f3f4f6', // Similar to bg-gray-100
                                    },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderColor: '#d1d5db', // Similar to border-gray-300
                                        borderRadius: '0.375rem', // Similar to rounded-md
                                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', // Similar to shadow-sm
                                    },
                                    '& .MuiOutlinedInput-root:hover': {
                                        borderColor: '#d1d5db', // Maintain the same border color on hover
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused': {
                                        borderColor: '#d1d5db', // Maintain the same border color when focused
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '8px 12px', // Similar to px-3 py-1
                                    },
                                }}
                            /> */}
                            <Button variant="outlined" sx={{ borderRadius: '7px', borderColor: '#E44190', mt: 2, color: '#E44190', textTransform: 'capitalize', '&:hover': { borderColor: '#E44190', color: '#E44190' } }}
                                className=" bg-gray-200 px-3 py-2 text-[#E44190]"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                            {/* <hr /> */}
                            <ul>
                                {messageHistory.map((message, idx) => (
                                    <div className="border border-gray-200 py-3 px-3" key={idx}>
                                        {message.name}: {message.message}
                                    </div>
                                ))}
                            </ul>
                        </div>
                    }
                </div>
            )}
        </>
    );
}
