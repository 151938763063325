import React, { useEffect, useState } from "react";
import { Typography, Grid, useMediaQuery, Box, useTheme, Menu, MenuItem } from "@mui/material";
import { MdMyLocation } from "react-icons/md";
import { IoIosCall, IoMdMail } from "react-icons/io";
import { MdDirections } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HospitalLocation from "./HospitalFacilityLocation";
import SecondaryNavbar from "./secondrynavbar";
import styles from "./styles.module.css";
import SearchComponent from "./search";
import Logo from "../../assets/parmitalogo.png";
import "./headerstyles.css";
import UserService from "../../services/UserService";
import axios from "axios";
import Popover from "@mui/material/Popover";
import ContainedButton, { OutlinedButton } from "../atoms/commonbutton";
import { CustomTextField } from "../atoms/TextField";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Overlay } from "../atoms/overlay";
import { localstore } from "../localstore/localstore";
import { v4 as uuidv4 } from "uuid";
import URL from "../../services/api_url";
import { jwtDecode } from "jwt-decode";
import { Call, Logout } from "@mui/icons-material";
import paramithalightIcon from '../../assets/home-images/Paramitha logo-Light.svg'
import paramithaDarkIcon from '../../assets/home-images/Paramitha-Hospital Logo-DarkMode.svg'
import paramithaTitleIcon from '../../assets/home-images/Paramitha Hospitsals-Title.svg'
import emergencyIcon from '../../assets/home-images/Call Emergency.svg'
import LogoutIcon from '../../assets/healthportal-icons/logout-outlined.svg'
import SwitchprofileIcon from '../../assets/healthportal-icons/switchprofile.svg';

import ChooseBranch from "./chooseBranch";
import { API } from "../../services/ApiService";
import { getPatientdatafromibm } from "../../redux/actions/actions";
import BranchUrl from "../../services/api_branch_url";


// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const settings = [
  {
    icon: null,
    label: 'Switch Profile',
  },
  {
    icon: null,
    label: 'Messages',
  },

  {
    icon: null,
    // icon: <img src={LogoutIcon} alt="logout" width={25} height={25} />,
    label: 'Logout',
  },
];
const SecondHeader = () => {
  const spanRef = React.useRef();
  const navigate = useNavigate();

  const theme = useTheme();
  const isXsScreen = useMediaQuery("(max-width:900px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMediumLargeScreen = useMediaQuery(theme.breakpoints.between(900, 1024));

  const selectedLocationName = useSelector((state) => state.branch.branch);
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);

  // const [branch, setBranch] = useState(null)
  const ibmid = localstore.getIbmId();
  const usertoken = localstore.getToken();
  const refreshToken = localstore.getRefreshToken();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [textBlur, setTextBlur] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [branchList, setBranchList] = useState([])
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch()
  const urls = BranchUrl()
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (val) => {
    if (val.label === 'Switch Profile') {
      navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/healthportal` : `/healthportal`);
      setAnchorElUser(null);

    } else if (val.label === 'Logout') {
      handleLogOut()
      setAnchorElUser(null);
    }
    setAnchorElUser(null);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (usertoken && ibmid) {
      dispatch(getPatientdatafromibm(urls, usertoken, ibmid))
    }
  }, [usertoken, ibmid])



  const refreshdata = {
    refreshToken: refreshToken,
    realmName: selectBranchInfo?.tenant || 'parimitha',
  };

  const [isRefreshing, setIsRefreshing] = useState(false);

  // useEffect(() => {
  //   const fetchNewAccessToken = async () => {
  //     try {
  //       if (usertoken) {
  //         const decoded = jwtDecode(usertoken);
  //         const exp = decoded.exp;
  //         const currentTime = Math.floor(Date.now() / 1000);

  //         // if (currentTime >= exp && !isRefreshing) {
  //         if (currentTime >= exp) {
  //           // setIsRefreshing(true);  // Start refreshing

  //           if (typeof refreshToken === 'string' && refreshToken.trim() !== '') {
  //             const response = await axios.post(
  //               `${URL.paramitha}/AccessTokenByRefreshToken`,
  //               refreshdata,
  //               {
  //                 headers: {
  //                   realm: selectBranchInfo?.tenant || 'parimitha',
  //                 },
  //               }
  //             );

  //             const { access_token, refresh_token } = response.data;
  //             localStorage.setItem("kctoken", access_token);
  //             localStorage.setItem("rctoken", refresh_token);
  //           } else {
  //             console.error("Invalid refresh token:", refreshToken);
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error refreshing access token:", error);
  //     } finally {
  //       setIsRefreshing(false);  // Stop refreshing
  //     }
  //   };

  //   fetchNewAccessToken();
  // }, [usertoken, refreshToken, selectBranchInfo]);

  useEffect(() => {
    const checkTokenExpiry = async () => {
      try {
        if (usertoken && refreshToken) {
          const decodedAccessToken = jwtDecode(usertoken);
          const decodedRefreshToken = jwtDecode(refreshToken);
          const accessExp = decodedAccessToken.exp;
          const refreshExp = decodedRefreshToken.exp;
          const currentTime = Math.floor(Date.now() / 1000);

          // Check if both access token and refresh token are expired
          if (currentTime >= accessExp && currentTime >= refreshExp) {
            console.error("Both access token and refresh token are expired. Redirecting to login.");
            handleLogoutPage();
          }
        }
      } catch (error) {
        console.error("Error checking token expiration:", error.response?.data || error.message);
      }
    };

    // Call the function periodically (e.g., every minute) to check token expiration
    const intervalId = setInterval(checkTokenExpiry, 60000);  // Check every 1 minute

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usertoken, refreshToken]);

  const handleLogoutPage = () => {
    // Clear tokens from localStorage
    localStorage.removeItem("kctoken");
    localStorage.removeItem("rctoken");

    // Redirect user to login page
    window.location.href = selectBranchInfo?.name ? `/${selectBranchInfo?.name}/login` : "/login"
    // Adjust according to your routing

    // Optionally show a message to the user
    alert("Your session has expired. Please log in again.");
  };


  const handleOnNearestLocation = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      // const { latitude, longitude } = position.coords;
    } catch {

    }
  }



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let randomId = sessionStorage.getItem("randomId");
  useEffect(() => {
    // if (!randomId) {
    const uniqId = uuidv4();
    sessionStorage.setItem("randomId", uniqId);
    // }
    handleOnNearestLocation()
  }, []);

  const handleLoginClick = () => {
    // UserService.doLogin()
    navigate(
      selectBranchInfo?.name ? `/${selectBranchInfo?.name}/login` : "/login"
    );
    handleClose();
  };
  const handleSignupClick = () => {
    navigate(
      selectBranchInfo?.name ? `/${selectBranchInfo?.name}/signup` : "/signup"
    );
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    localStorage.removeItem("kctoken");
    localStorage.removeItem("IbmId");
    localStorage.removeItem("rctoken")
    localStorage.removeItem("fixedIbmId")
    localStorage.removeItem("pId")
    localStorage.removeItem("userName")
    localStorage.removeItem("participantId")
    localStorage.removeItem("visitorId")
    window.location.href = '/';

    // navigate(
    //   selectBranchInfo?.name ? `/${selectBranchInfo?.name}/login` : "/login"
    // );
  };

  const handleClickHome = () => {

    navigate('/')

  }
  return (
    <>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: isSticky ? 10 : 200,
          backgroundColor: isSticky ? "#FDF3F7" : "#fff",
        }}

      >
        <Grid
          container
          className="flex justify-between items-center"
          ref={spanRef}
          sx={{
            padding: {
              md: '0px 80px', lg: "0px 90px"
            },
            position: "relative",
            // minWidth: "370px",
            ...(isSmallScreen && {
              padding: "10px 10px", // Adjust padding for small screens
            }),
            ...(isMediumScreen && {
              padding: "10px 40px", // Adjust padding for medium screens
            }),
            ...(isMediumLargeScreen && {
              padding: "10px 40px", // New padding for medium-large screens
            }),
            height: 80
          }
          }
          alignItems='center'
        >

          <Grid item xs={8} sm={8} md={isSticky ? 3.5 : 2.5} lg={isSticky ? 3 : 3}>
            {isSticky ? (
              <div className="flex gap-2">
                <img src={paramithalightIcon} alt="paramithadark" style={{ height: 50 }} />
                <HospitalLocation sticky={isSticky} branchList={branchList} />
              </div>
            ) : (
              <div className="flex gap-2 cursor-pointer" onClick={handleClickHome}>
                <img src={paramithalightIcon} alt="paramithadark" style={{ height: 60 }} />
                <img src={paramithaTitleIcon} alt="paramithadark" style={{ height: 60 }} />
              </div>

            )}
          </Grid>

          <Grid item xs={isSticky ? 3 : 1.5} sm={isSticky ? 3 : 1.5} md={isSticky ? 0.1 : 4} lg={isSticky ? 1 : 5.5}>
          </Grid>


          <Grid item xs={isSticky ? 1 : 2.5} sm={isSticky ? 1 : 2.5} md={isSticky ? 6.4 : 3} lg={isSticky ? 6.5 : 2}>
            {!isXsScreen ? (
              <>
                {isSticky ? (<>
                  <SecondaryNavbar
                    spanRef={spanRef}
                    transparent={false}
                    sticky={isSticky}

                    handleLogOut={handleLogOut}
                  /></>) :
                  <div className="flex items-center gap-3">
                    <div>
                      <img src={emergencyIcon} alt="emergency" />
                    </div>
                    <div>
                      <div style={{ color: "#303030", fontWeight: 'lighter', fontSize: "14px" }}>
                        Emergency
                      </div>
                      <div style={{ color: "#155774", fontWeight: 'normal' }}>
                        +91 40 287 22122
                      </div>
                    </div>
                  </div>
                }
              </>
            ) : (<>{
              isSticky ? <>
                <SecondaryNavbar
                  spanRef={spanRef}
                  transparent={false}
                  sticky={isSticky}

                  handleLogOut={handleLogOut}
                /></> :
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={emergencyIcon} alt="emergency" width={29} height={29} />
                  <div style={{ color: "#303030", fontWeight: 'lighter', fontSize: "14px", textAlign: 'center' }} className="mt-1">
                    Emergency
                  </div>
                </div>
            }</>)
            }
          </Grid >
          {/* <Grid item xs={1} sm={1} md={2}>

        </Grid> */}

          {
            ibmid && usertoken ? (
              isXsScreen ? (
                ""
              ) : (
                <Grid item md={2} lg={1.5} className={[styles.loginButton].join(" ")} sx={{
                  padding: "8px 10px", borderColor: '#E44190'
                }}
                  onClick={handleOpenUserMenu}
                // onClick={() =>
                //   navigate(
                //     selectBranchInfo?.name
                //       ? `/${selectBranchInfo?.name}/healthportal`
                //       : "/healthportal"
                //   )
                // }
                >
                  <Box sx={{ cursor: 'pointer', color: '#E44190', textAlign: 'center', fontSize: { md: 14, lg: 14 } }}

                  >
                    My health Portal
                  </Box>
                </Grid>
              )
            ) : isXsScreen ? (
              ""
            ) : (
              <>
                {usertoken ? (
                  <Grid item md={2} lg={1.5} className={[styles.loginButton].join(" ")}>
                    <span className="cursor-pointer flex justify-between" onClick={handleLogOut}>
                      Log Out <img src={LogoutIcon} alt="logout" width={18} height={18} />
                    </span>
                  </Grid>
                ) : (
                  <Grid
                    item
                    md={2} lg={1.5} onClick={handleClick}
                    sx={{
                      background: isSticky ? "#E44190" : "#E44190",
                      color: isSticky ? "#fff" : "#fff",
                      padding: "10px 10px",
                      borderRadius: "12px",
                      textAlign: 'center'
                    }}
                  >
                    <span>
                      <span
                        // onClick={handleLoginClick}
                        // UserService.doLogin()
                        className="cursor-pointer"
                      >
                        Signup
                      </span>{" "}
                      /
                      <span
                        // onClick={handleSignupClick}
                        className="cursor-pointer"
                      >
                        {" "}
                        Login
                      </span>
                    </span>
                  </Grid>
                )}{" "}
              </>
            )
          }
        </Grid >
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting, index) => (
            <MenuItem key={setting.id || index} onClick={() => handleCloseUserMenu(setting)}>
              <div className="flex items-center gap-3">
                <Typography sx={{ pointerEvents: setting.label === "Messages" ? "auto" : 'none', opacity: setting.label === "Messages" ? "0.2" : 1 }}>{setting.label}</Typography>
                <Typography>{setting.icon}</Typography>
              </div>
            </MenuItem>
          ))}
        </Menu>

        {open ? <Overlay onClick={handleClose} /> : null}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ zIndex: 1000, top: 25 }}
        >
          <Box sx={{ p: "20px 20px" }}>
            <Typography sx={{ fontWeight: 600, mb: "5px" }}>
              New to Paramitha Hospital ?
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              Is this the first time you are visiting our Hospital or
              <br /> Website ? If yes, Click Register Now to Signup
            </Typography>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <ContainedButton
                width="160px"
                height="32px"
                bgcolor="#1B5775"
                text="Register Now"
                borderRadius="10px"
                onClick={handleSignupClick} // onClick event handler
                startIcon={null}
                endIcon={null}
              />
            </Box>

            <Box sx={{ mt: "30px" }}>
              <Box
                sx={{
                  pointerEvents: !textBlur ? "none" : "auto",
                  opacity: !textBlur ? 0.4 : 1,
                }}
              >
                <Typography sx={{ fontWeight: 600, mb: "5px" }}>
                  Already visited us before ?
                </Typography>
                <Typography sx={{ mb: "5px", fontSize: 14, fontWeight: 500 }}>
                  If your previously visited Paramitha Hospital before, or <br />{" "}
                  Registered with us before, please continue to login
                </Typography>
                <Typography
                  sx={{ mb: "2px", mt: "18px", fontSize: 14, fontWeight: 500 }}
                >
                  Registered Phone Number / User Name
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <CustomTextField
                    sx={{
                      ".MuiInputBase-root": {
                        paddingRight: "0px !important",
                      },
                    }}
                    placeholder="Enter Your Phone Number"
                    type={"text"}
                    borderColor="#707070"
                    width="100%"
                    borderRadius="10px"
                    padding="0px"
                    icon={
                      <ArrowForwardIcon
                        sx={{
                          backgroundColor: "#1B5775",
                          fontSize: "40px",
                          color: "white",
                          borderRadius: "0px 10px 10px 0px",
                          // border: "1px solid blue"
                        }}
                      />
                    }
                  // value={phoneNumber}
                  // onChange={handleChangePhoneNumber}

                  // onClick={handleClickPhoneNumber}
                  />
                </Box>
              </Box>

              <Box sx={{ mt: "20px", display: "flex", justifyContent: "center", mb: '30px' }}>

                <OutlinedButton width="160px" height="35px" borderColor="#1B5775" text="Login Options" color="#1B5775" borderRadius="10px"
                  onClick={handleLoginClick} // onClick event handler
                  // disabled={!fullName || !username || !phonenum || !EmailId || !createpassword}
                  startIcon={null}
                  endIcon={null}
                />
              </Box>
            </Box>
          </Box>
        </Popover>
      </div >
      {
        isSticky ? null :
          <ChooseBranch sticky={isSticky} branchList={branchList} />
      }
    </>

  );
};

export default SecondHeader;
