import React, { useEffect, useState } from 'react'
import { Avatar, Box, FormControl, Grid, MenuItem, Paper, Select, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import HealthprofileIcon from '../../assets/healthportal-icons/healthprofile.svg'
import ConsultationsIcon from '../../assets/healthportal-icons/consultations.svg'
import MedicationIcon from '../../assets/healthportal-icons/medication-orders.svg'
import MedicalRecordIcon from '../../assets/healthportal-icons/journal-medical.svg'
import PaybillIcon from '../../assets/healthportal-icons/payyourbillonline.svg'
import DiagnosticIcon from '../../assets/healthportal-icons/diagnostic-reports.svg'
import { useDispatch, useSelector } from 'react-redux';
import CareReminders from './carereminders';
import { getPatientIdDetails } from '../../redux/actions/actions';
import BranchUrl from '../../services/api_branch_url';
import LogoutIcon from '../../assets/healthportal-icons/logout-outlined.svg'
import TeleImg from '../../assets/images/consult online.svg';
import SearchStrongIcon from '../../assets/healthportal-icons/search-strong.svg'

import { localstore } from '../localstore/localstore';
import StringAvatar from '../atoms/stringAvatar';
import { calculateAge } from '../atoms/calculateAge';
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import { Delete } from '@mui/icons-material';

const TabItem = ({ icon, label, selected, onClick, to }) => (

    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            cursor: 'pointer',
            // backgroundColor: selected ? '#EFEFEF' : 'transparent',
            borderRadius: "10px",

            bgcolor: selected ? '#F8CCE1' : 'transparent',
            p: "5px 10px",
            // transition: 'box-shadow 0.3s ease-in-out',
        }}
        onClick={onClick}
    >
        {/* {icon && React.isValidElement(icon) && React.cloneElement(icon, { fontSize: 'inherit' })} */}

        <img src={icon} alt="live" width={20} height={20} />
        <Typography sx={{ color: selected ? '#155775' : 'black', fontWeight: 500, fontSize: { xs: '14px', sm: '14px', md: "14px", lg: '16px' } }}>
            {label}
        </Typography>
    </Box >
);

const Headertitle = (props) => (
    <Box className='flex align-item' gap={"10px"}>
        <img src={props.icon} alt="consult" width={20} height={20} />
        <Typography color={'#155775'} className='text-sm fntwt-600'  >
            {props.title}</Typography>
    </Box>

)
function PersonDetails() {
    const isXsScreen = useMediaQuery("(max-width:900px)");
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
    const patientDetails = useSelector((state) => state.paramitha.patientData)
    const [profileOpen, setProfileOpen] = useState(false)
    const initialTab = location.pathname.split('/').pop();
    const urls = BranchUrl()
    const ibmId = localstore.getIbmId()
    const usertoken = localstore.getToken()
    const fixedIbmId = localstore.getPatientIbmId()
    const [selectedTab, setSelectedTab] = useState(initialTab);

    useEffect(() => {
        setSelectedTab(initialTab);
        dispatch(getPatientIdDetails(urls, usertoken, fixedIbmId))
    }, [initialTab]);


    const handleTabClick = (tab) => {
        if (tab === "patient") {
            setSelectedTab(tab);
            navigate(selectedbranch?.name ? `/${selectedbranch?.name}/patient` : `/patient`);
        } else {
            setSelectedTab(tab);
            navigate(selectedbranch?.name ? `/${selectedbranch?.name}/patient/${tab}` : `/patient/${tab}`);

        }
    };

    const handleTabChange = (event) => {
        const tab = event.target.value;
        if (tab === "patient") {
            setSelectedTab(tab);
            navigate(selectedbranch?.name ? `/${selectedbranch?.name}/patient` : `/patient`);
        } else {

            setSelectedTab(tab);
            navigate(selectedbranch?.name ? `/${selectedbranch?.name}/patient/${tab}` : `/patient/${tab}`);
        }
    };
    const handleLogout = () => {
        localStorage.removeItem("kctoken");
        localStorage.removeItem("IbmId");
        localStorage.removeItem("rctoken")
        localStorage.removeItem("userName")
        localStorage.removeItem("participantId")
        localStorage.removeItem("visitorId")
        // navigate('/')
        window.location.href = '/';
        // window.location.reload(true);
    }
    return (
        <Box sx={{ px: { xs: '20px', sm: '20px', md: '30px', lg: "80px" } }}>
            {/* <Paper sx={{ p: "10px", mt: '20px', borderRadius: '5px' }}>
                <Grid
                    container
                    className="flex justify-between items-center"
                    sx={{
                        padding: { xs: '0px 5px', md: "0px 10px" },
                        position: "relative",
                    }}
                >
                    <Grid item xs={8} sm={4} md={4} className="flex items-center" gap={"10px"}>
                        <>
                            <Avatar variant="rounded"
                                {...StringAvatar(
                                    `${`${patientDetails?.name?.[0]?.given?.[0].toUpperCase()} ${patientDetails?.name?.[0]?.family.toUpperCase() ?? ''}`.slice(0, 12)}${`${patientDetails?.name?.[0]?.given?.[0].toUpperCase()} ${patientDetails?.name?.[0]?.family.toUpperCase() ?? ''}`.length > 12 ? '...' : ''}`)}
                            />

                            <div>
                                <Typography
                                    fontSize={'14px'}
                                    fontWeight={500}

                                    textTransform={'capitalize'}

                                >
                                    {`${patientDetails?.name?.[0]?.given?.[0]} ${patientDetails?.name?.[0]?.family ? patientDetails?.name?.[0]?.family : ''}`.slice(0, 12)}
                                    {`${patientDetails?.name?.[0]?.given?.[0]} ${patientDetails?.name?.[0]?.family ? patientDetails?.name?.[0]?.family : ''}`.length > 12 ? '...' : ''}
                                </Typography>
                                <Typography fontSize={'14px'}>
                                    {patientDetails?.birthDate ? `${calculateAge(patientDetails?.birthDate)} Y` : ''}/{patientDetails?.gender && patientDetails?.gender.charAt(0).toUpperCase()}
                                    <Typography component={'span'} fontSize={'14px'} color={'#0D00AD'} style={{ marginLeft: '4px' }}>Link ABHA</Typography>
                                </Typography>
                            </div>
                        </>


                    </Grid>


                    <Grid item md={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                        {!isXsScreen &&
                            <Paper elevation={5} className='paperIcons' sx={{ borderRadius: '25px', width: { xs: 20, sm: 35, md: 40 }, height: { xs: 20, sm: 35, md: 40 }, padding: '10px', marginRight: '15px' }}>
                                <img src={SearchStrongIcon} alt="search" />
                            </Paper>
                        }
                        <Paper elevation={5} className='paperIcons' sx={{ borderRadius: '25px', width: { xs: 35, sm: 35, md: 40 }, height: { xs: 35, sm: 35, md: 40 }, padding: '10px', marginRight: '15px' }}>
                            <img src={TeleImg} alt="tele" />
                        </Paper>

                        <Paper elevation={5} sx={{ borderRadius: '25px', width: { xs: 35, sm: 35, md: 40 }, height: { xs: 35, sm: 35, md: 40 }, padding: '10px', cursor: 'pointer' }} onClick={handleLogout} >
                            <Tooltip title="Logout">
                                <img src={LogoutIcon} alt="logout" />
                            </Tooltip>
                        </Paper>
                    </Grid>

                </Grid>
            </Paper> */}
            <>
                {isXsScreen && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: "15px", p: '0px 5px' }}>
                        {selectedTab === "healthportal" ?
                            <Headertitle title="Health profile" icon={HealthprofileIcon} />
                            : selectedTab === "consultations" ?
                                <Headertitle title="Consultations" icon={ConsultationsIcon} />
                                : selectedTab === "medicalrecords" ?
                                    <Headertitle title="Medical Records" icon={MedicalRecordIcon} />
                                    : selectedTab === "medication" ?
                                        <Headertitle title="Medication Orders" icon={MedicationIcon} />
                                        : selectedTab === "diagnostic" ?
                                            <Headertitle title="Diagnostic Reports" icon={DiagnosticIcon} />
                                            : selectedTab === "paybill" ?
                                                <Headertitle title="Pay your Bill Online" icon={PaybillIcon} />
                                                : null
                        }

                        <FormControl >
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedTab}
                                label="Select Tab"
                                onChange={handleTabChange}
                                variant='standard'
                            >
                                <MenuItem value="patient">My Health Profile</MenuItem>
                                <MenuItem value="consultations">Consultations</MenuItem>
                                <MenuItem value="medicalrecords">Medical Records</MenuItem>
                                <MenuItem value="medication">Medication Orders</MenuItem>
                                <MenuItem value="diagnostic">Diagnostic Reports</MenuItem>
                                <MenuItem value="paybill">Pay your Bill Online</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}
            </>
            <Grid container sx={{ mt: "15px", xs: '2px' }}>

                <Grid item md={2.5} lg={2} >
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            flexDirection: { md: 'column' },
                            gap: 1,
                            // mt: { sm: '80px', xs: '0px', md: "60px", },
                            // position: 'fixed',
                            p: "10px 10px 0px 0px "
                        }}
                    >

                        <TabItem
                            icon={HealthprofileIcon}
                            label="My Health Profile"
                            selected={selectedTab === 'patient'}
                            onClick={() => handleTabClick("patient")}
                        />

                        <TabItem
                            icon={ConsultationsIcon}
                            label="Consultations"
                            selected={selectedTab === 'consultations'}
                            onClick={() => handleTabClick("consultations")}
                        />

                        <TabItem
                            icon={MedicalRecordIcon}
                            label="Medical Records"
                            selected={selectedTab === 'medicalrecords'}
                            onClick={() => handleTabClick("medicalrecords")}
                        />
                        <TabItem
                            icon={MedicationIcon}
                            label="Medication Orders"
                            selected={selectedTab === 'medication'}
                            onClick={() => handleTabClick("medication")}
                        />
                        <TabItem
                            icon={DiagnosticIcon}
                            label="Diagnostic Reports"
                            selected={selectedTab === 'diagnostic'}
                            onClick={() => handleTabClick("diagnostic")}
                        />
                        <TabItem
                            icon={PaybillIcon}
                            label="Pay your Bill Online"
                            selected={selectedTab === 'paybill'}
                            onClick={() => handleTabClick("paybill")}
                        />


                    </Box>
                    <div className='mt-5 pr-2'>
                        <ContainedButton text="Delete Profile" bgcolor="#E44190" width="100%" sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: '10px' }} endIcon={<Delete />} />

                    </div>

                    {/* {!isXsScreen && <Switchprofile relatedPerson={relatedPerson} selectedbranch={selectedbranch} fixedPatient={fixedPatient} loading={loading} setLoading={setLoading} stringAvatar={stringAvatar} />} */}
                    {/* {!isXsScreen && (
                        <Paper sx={{ p: '10px', mt: 2 }}>
                            <Typography fontSize={14}> Need to Manage Health ID for your family members ?</Typography>
                            <ContainedButton text="New Profile" startIcon={<img src={NewProfileIcon} alt="newprofile" />} bgcolor="#205072" mt={"10px"} height="33px" borderRadius="10px" width="150px" onClick={() => setProfileOpen(true)} />
                        </Paper>
                    )}
                    <AddProfile open={profileOpen} setProfileOpen={setProfileOpen} selectedbranch={selectedbranch} patientDetails={patientDetails} /> */}
                </Grid>
                <Grid item md={9.5} lg={8.5} xs={12} sm={12} p={{ xs: '1px 10px', sm: '1px 10px', md: "5px 80px", lg: '5px 80px' }}>
                    <Outlet />
                </Grid>
                {/* <Grid item md={2.5} >

                    {!isXsScreen &&
                        <CareReminders />}
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default PersonDetails