import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
  Card,
  Paper,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ServicesTab, locationJson } from "../../../shared/constants";
import URL from "../../../services/api_url";
import axios from "axios";
import healthdeparticon from '../../../assets/healthcareprograms/Health Department 2.svg';
import { useNavigate } from "react-router-dom";
import BranchUrl from "../../../services/api_branch_url";
import { makeStyles } from "@mui/styles";
import { ArrowForward } from "@mui/icons-material";
import { selectedBranchInfo } from "../../../redux/slices/SelectedBranchSlice";
import { branchInfo } from "../../../redux/slices/branchSlice";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  papercard: {
    [theme.breakpoints.down('md')]: {
      height: '130px !important',
      padding: '15px !important'
    },
    "&:hover": {
      boxShadow: " 0 -3px 5px 0 #E44190, 0 3px 10px 0 #E44190; !important",  // Change to your desired hover background color
      color: "#FFFFFF",            // Change to your desired hover text color
    },
  },

  img: {
    width: '54px',
    height: 54,
    [theme.breakpoints.down('md')]: {
      width: '35px', // Adjust width for extra small screens (phones)
      height: 35
    },
  },

}))

const HealthTab = ({ branch }) => {
  const [serviceValue, setServiceValue] = React.useState(null);
  const [inputServiceValue, setInputServiceValue] = React.useState("");
  const [departmentInfo, setDepartmentInfo] = React.useState([]);
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const baseurl = BranchUrl()
  const Classes = useStyles();
  const [loading, setLoading] = useState(false)

  const branchId = branch?.id ? branch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`
  useEffect(() => {
    if (branch) {
      setLoading(true)
      axios.get(`${process.env.REACT_APP_API_URL_v4}parimitha/${branchId}/Organization?type=dept`,
        {
          headers: {
            "X-FHIR-TENANT-ID": "parimitha",
            "Realm": "parimitha",
            "X-FHIR-DSID": "fhirparimitha"
          },
        }
      )
        .then((res) => {
          // let sortList = res.data?.entry ? res.data?.entry.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value) : []
          let sortList = res.data.entry.sort((a, b) =>
            (a.resource.identifier?.find(id => id.system === 'https://positionofdepartments/')?.value || '999')
              .localeCompare(b.resource.identifier?.find(id => id.system === 'https://positionofdepartments/')?.value || '999')
          );
          setDepartmentInfo(sortList || []);
        })
        .catch((err) => console.log(err)).finally(() => {
          setLoading(false)
        })
    }

    // axios.get(`${ URL.getHeatlthcareservice } ? _count = 200`, {
    //   headers: {

    //     'X-FHIR-TENANT-ID': 'parimitha',
    //     'X-FHIR-DSID': `fhir${ 'parimitha'}`,
    //     // 'realm': REALM
    //   }
    // }).then((res) => {
    //   setDepartmentInfo(res?.data?.entry)
    //   console.log(res)
    // }).catch((err) => console.log(err))
  }, [branch]);


  const handleSelectDept = (title, id) => {
    localStorage.setItem("brName", branch?.name);
    localStorage.setItem("selectOrg", branch?.name);

    localStorage.setItem("selectedbranch", JSON.stringify(branch));
    dispatch(branchInfo(branch?.name));
    dispatch(selectedBranchInfo(branch));

    let name = title.replace(/\//g, '')
    navigate(branch?.name ? `/${branch?.name}/healthcaredept/${name}/${id}` : `/healthcaredept/${name}/${id}`)

  }

  return (
    <Box
      sx={{
        p: {
          xs: "0px",
          sm: "0px",
          md: 0
          // "&.MuiBox-root css-19kzrtu": { p: "0px" }
        },
        mt: 2
      }}
    >


      <Box sx={{ height: { md: 600, lg: 430 } }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container columnSpacing={6} rowSpacing={4} className="pt-5">

            {departmentInfo && departmentInfo?.length > 0 ? (

              departmentInfo?.slice(0, 8)?.map((val, index) => {
                // const imgSrc = val?.resource?.identifier?.[0]?.value
                // const isValidImage = typeof imgSrc === 'string' && imgSrc.trim() !== '' && isNaN(imgSrc);
                const imgSrc = val?.resource?.identifier?.filter((val) => val?.system === `${process.env.REACT_APP_API_Docsteth}logo`)?.[0]?.value;                                        // const imgSrc = val?.resource?.identifier?.[0]?.value

                // filter((item) => item?.system === 'https://dev.api.docsteth.com/api/api/v1/logo');
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    {/* <Box className={`${Classes.papercard} flex items-center cursor-pointer px-2`} */}
                    <Box className='flex items-center cursor-pointer px-2'
                      elevation={2}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        cursor: 'pointer',
                        // mt: 1,
                        opacity: 1,
                        p: { xs: '15px 10px', md: '25px 10px' },
                        backgroundColor: '#FDF3F7',
                        height: { xs: '140px', md: '160px' },
                        border: '0.5px solid #E54290',
                        borderRadius: '12px',
                        color: "#1D5775",
                        "&:hover": {
                          backgroundColor: '#E54290',
                          color: '#FFFFFF'
                        }
                      }}
                      onClick={() => handleSelectDept(val?.resource?.name, val?.resource?.id)}
                    >
                      <img
                        src={imgSrc || healthdeparticon}  // Use imgSrc if valid; otherwise, use the static image
                        className={Classes.img}
                        style={{ marginBottom: '8px' }}
                        alt=""
                      />
                      <Box className="truncate" sx={{ width: '100%' }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: 'capitalize',
                            opacity: "100%",
                          }}
                        >
                          {val?.resource?.name}
                        </Typography>
                        {/* <Typography
                          sx={{
                            color: "#000000",
                            fontSize: "12px",
                            opacity: "100%",
                          }}
                          className="truncate"
                        >
                          {val?.resource?.alias}
                        </Typography> */}
                      </Box>
                    </Box>
                  </Grid>
                )
              })
            ) : <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="h6" sx={{ color: "#525C79" }}>
                  No data available
                </Typography>
              </Box>
            </Grid>}
          </Grid>
        )}
      </Box>

      {departmentInfo?.length >= 8 &&
        <Box sx={{ display: "flex", justifyContent: "center", mt: { xs: 3, sm: 3, md: 5, lg: 2 } }}>
          <Button
            variant="outlined"
            onClick={() => navigate(selectedBranchInfo?.name ? 'healthcaredept' : 'healthcaredept')}
            sx={{
              borderColor: "#E54290",
              textTransform: "capitalize",
              color: "#E54290",
              borderRadius: "10px",
              width: { xs: '200px', md: '255px' },
              height: { xs: '44px', md: "54px" },
              '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
            }}
            endIcon={<ArrowForward />}
          >
            <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}> See All Departments</Typography>
          </Button>
        </Box>
      }
    </Box >
  );
};

export default HealthTab;
