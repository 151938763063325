import { Box, IconButton } from '@mui/material'
import DOMPurify from 'dompurify';
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PostActions from './PostActions';
import { ArrowBack } from '@mui/icons-material';

function ViewArticle() {
    const location = useLocation()
    const articleData = location?.state?.docFeed?.articleData;
    const navigate = useNavigate()
    // console.log(location, "lco")
    const { id } = useParams()
    const sanitizedHtml = DOMPurify.sanitize(articleData?.articleDescription, {
        FORBID_TAGS: ['style', 'font'],
        FORBID_ATTR: ['style']
    });
    // console.log(id, "idd")
    return (
        <Box sx={{
            p: {
                xs: "10px 0px", sm: "10px 0px", md: "10px 80px", lg: "10px 90px"
            }
        }}>
            <IconButton onClick={() => navigate(- 1)
            } > <ArrowBack /></ IconButton>
            <div className='text-center text-xl font-medium'>

                {articleData?.articleTitle}
            </div>
            <div
                className='mt-2'
                dangerouslySetInnerHTML={{
                    __html: sanitizedHtml
                }}
            />
            <div className='mt-2'>
                <PostActions item={location?.state?.docFeed} />
            </div>

        </Box>
    )
}

export default ViewArticle