import React from 'react'
import { Box, Typography, Divider } from '@mui/material'
import { LocationOn, NotificationsNone, Vaccines } from '@mui/icons-material'
import './healthportal.css'
function CareReminders() {
    return (
        <div>
            <Box className="carereminder">
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 600 }}><NotificationsNone /> Care Reminders</Typography>

                {/* <Typography className='carefont fntsz-15' mt="10px" >Consultation Appointments</Typography>
                <Typography className='mt1'>Dr Venkat Reddy K</Typography>
                <Typography fontSize='13px' mt='3px' bgcolor="#F1FAFF"> <LocationOn sx={{ fontSize: 13 }} /> Paramita Children's Hospital,Kompally</Typography>
                <Typography fontSize='13px' mt='3px'>Due Date     21-Jan-2023</Typography> */}
            </Box>
            {/* <Divider />
            <Box>
                <Typography className='carefont fntsz-15' mt="10px" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><Vaccines />Vaccination</Typography>
                <Typography>  DTaP Vaccine Recommended</Typography>
                <Typography fontSize='13px' mt='3px' bgcolor="#F1FAFF"><LocationOn sx={{ fontSize: 13 }} /> Paramita Children's Hospital,Kompally</Typography>
                <Typography fontSize='13px' mt='3px' >Due Date     21-Jan-2023</Typography>
            </Box> */}
        </div >
    )
}

export default CareReminders