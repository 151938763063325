import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FaArrowRightToBracket } from "react-icons/fa6";

import styles from "./styles.module.css";
import { navbarJson } from "../../shared/utils";
import { useNavigate } from "react-router-dom";
import EmergencyPopup from "../branchhome/EmergencyPopup";
import { useSelector } from "react-redux";
import UrgentCarePopup from "../branchhome/UrgentcarePopup";
import { Cancel, Close } from "@mui/icons-material";
import { localstore } from "../localstore/localstore";
import TelemedicinePopup from "../branchhome/TelemedicinePopup";

const NavbarMenuItems = ({ menuItemsData, handleCloseNavMenu, onClose }) => {
  const navigate = useNavigate()

  const lengthOfItems = menuItemsData.length;

  const [isEmergencyOpen, setIsEmergencyOpen] = useState(false);
  const [isUrgentCareOpen, setIsUrgentCareOpen] = useState(false)
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
  const usertoken = localstore.getToken()
  const [telemedicineOpen, setTelemedicineOpen] = useState(false)

  const ibmId = localstore.getIbmId()

  const handleCloseDialog = () => {
    setIsEmergencyOpen(false);
  };
  const handleUrgentCloseDialog = () => {
    setIsUrgentCareOpen(false);
  };

  const handleTeleCloseDialog = () => {
    setTelemedicineOpen(false);
  };
  const handleItemClick = (item) => {
    if (item.url) {
      onClose(); // Close the popover\
      navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}${item.url}` : item.url);
      handleCloseNavMenu()
    } else if (item.title === "Emergency Services") {
      setIsEmergencyOpen(true);

    } else if (item.title === "Urgent Care") {
      setIsUrgentCareOpen(true);


    } else if (item.title === "Telemedicine") {
      setTelemedicineOpen(true);
    }
  };
  const handleLogOut = () => {
    localStorage.removeItem("kctoken");
    localStorage.removeItem("IbmId");
    localStorage.removeItem("rctoken")
    localStorage.removeItem("userName")
    localStorage.removeItem("participantId")
    localStorage.removeItem("visitorId")
    window.location.href = '/';
  }
  return (
    <div className={styles.container}>
      <Grid container columnSpacing={3} rowSpacing={2} className="NavbarMenuItems">
        <Grid item xs={12} md={3} >
          <div>
            <Grid container
              className={styles.portal}
            >
              {navbarJson.map((item, index) => (
                <Grid item key={index} className={styles.navbarItem} gap={2} onClick={() => handleItemClick(item)}>
                  {React.createElement(item.icon, {
                    style: { color: "#fff", fontSize: "40px" },
                  })}
                  <div style={{ paddingBottom: "15px", cursor: 'pointer', paddingTop: "10px" }}>
                    <div style={{ color: 'white' }}
                    // onClick={(() => item?.url ? navigate(item.url) : console.log(''))}
                    >
                      {item.title}
                    </div>
                    <div
                      // className="text-[11px] text-white"
                      className={[styles.descriptionText]}>
                      {item.description}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
            <div className={styles.loginbuttoncontainer}>
              <div >
                <div className={[styles.patientportaltext].join(" ")}>Patient Portal</div>

                <div className={styles.logintext}>
                  {usertoken && ibmId ? <span className="cursor-pointer"
                    onClick={
                      handleLogOut
                      // onClose()
                    }>Logout</span> :
                    // onClick={() => {
                    //   handleLogOut()
                    //   // onClose()
                    // }}>Logout</span> :
                    <div>
                      <span className="cursor-pointer"
                        onClick={() => {
                          navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/login` : '/login')
                          onClose()
                        }}>Login</span>
                      <span className="px-2">/</span>
                      <span className="cursor-pointer" onClick={() => {
                        navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}/signup` : '/signup')
                        onClose()
                      }}>Register</span>
                    </div>
                  }

                </div>
              </div>
              <FaArrowRightToBracket className={styles.righttobracketicon} style={{ fontSize: '38px' }} />
            </div>
          </div>
        </Grid >
        <Grid item xs={0} sm={0} md={0.5}>
          <Divider
            style={{ backgroundColor: "#fff", width: "1%" }}
            orientation="vertical"
            variant="medium"
          />
        </Grid>
        {/* <Grid item md={8.5}>
          <Grid container rowGap={10} columnGap={10}>
            {menuItemsData?.map((serviceMenu, index) => {
              return (
                <Grid
                  item
                  style={{ p: "10px" }}
                  // md={12 / lengthOfItems}
                  // className={styles.serviesmenugridcontainer}
                  key={index}
                >
                  <Grid className="text-white text-base pb-3 pt-1  " >
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>{serviceMenu.title}</Typography>
                  </Grid>
                  {serviceMenu.services.map((service, index) => (
                    <Grid className="text-sm pb-5 text-white" key={index} style={{ fontWeight: "lighter" }} onClick={(() => {
                      if (service?.url) {
                        onClose()
                        navigate(service?.url)
                      }
                    })}>
                      <Typography sx={{ fontSize: "14px", cursor: 'pointer' }}> {service?.name}</Typography>
                    </Grid>
                  ))}
                </Grid>
              );
            })}
          </Grid>
        </Grid> */}


        {/* test */}

        <Grid item xs={12} md={8.5}>
          <Grid container columnSpacing={10} justifyContent={{ xs: 'center', sm: 'center', md: 'start' }}>
            {menuItemsData?.map((serviceMenu, index) => (
              <Grid
                item
                sx={{ padding: { xs: "0px 10px", sm: "0px", md: "0px" }, textAlign: { xs: "center", sm: "left" } }} // Center the content
                key={index}
              >
                <div className="text-white text-base pb-3 pt-1">
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    {serviceMenu.title}
                  </Typography>
                </div>
                {serviceMenu.services.map((service, index) => (

                  <div
                    className="text-sm pb-5 text-white"
                    key={index}
                    style={{ fontWeight: "lighter", cursor: "pointer" }}
                    onClick={() => {
                      if (service?.url) {
                        onClose();
                        navigate(selectBranchInfo?.name ? `/${selectBranchInfo?.name}${service?.url}` : service?.url);
                      }
                    }}
                  >
                    {console.log(service, "service of nav bar")}
                    <Typography sx={{ fontSize: "14px", pointerEvents: service.name === "Diagnostics Tests" && "" }}>{service?.name}</Typography>

                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <EmergencyPopup open={isEmergencyOpen} handleClose={handleCloseDialog} />
        <UrgentCarePopup open={isUrgentCareOpen} handleClose={handleUrgentCloseDialog} setIsUrgentCareOpen={setIsUrgentCareOpen} />
        {/* {item.title === "Telemedicine" && */}
        <TelemedicinePopup open={telemedicineOpen} handleClose={handleTeleCloseDialog} setTelemedicineOpen={setTelemedicineOpen} />
        {/* } */}
      </Grid >

      <div className="text-center" onClick={onClose}>
        <IconButton sx={{ color: '#FFFFFF' }}><Close sx={{ fontSize: '32px' }} /></IconButton>
      </div>
    </div >
  );
};

export default NavbarMenuItems;
