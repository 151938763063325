import React, { useEffect, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, InputAdornment, MenuItem, Paper, Popper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import EmergencyImg from '../../assets/images/ambulance-icon.svg';
import { CustomTextField } from '../atoms/TextField';
import { MyLocation } from '@mui/icons-material';
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import Location from './location';
import { useScroll } from 'react-spring';
import { IoIosCall } from 'react-icons/io';
import URL from '../../services/api_url';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import { Commoncalender } from '../atoms/commoncalender';
import * as Yup from 'yup';
import { localstore } from '../localstore/localstore';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import UrgentcareServices from './UrgentcareServices';
import ReCAPTCHA from 'react-google-recaptcha';


function UrgentCarePopup({ open, handleClose, setIsUrgentCareOpen }) {
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
    const selectedBranchId = selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`

    const patientDetails = useSelector(state => state.paramitha.patientDetails)
    const [phoneNumber, setPhoneNumber] = useState('')

    // console.log(patientDetails, "patientDestails")
    const ibmId = localstore.getIbmId()
    const [show, setShow] = useState(ibmId ? 2 : 1);
    const usertoken = localstore.getToken()
    const [value, setValue] = useState(null);
    const [verifyOtp, setVerifyOtp] = useState("");
    const [requestOpen, setRequestOpen] = useState(true)
    const [verifyOpen, setVerifyOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const randomId = sessionStorage.getItem('randomId');
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const dispatch = useDispatch()
    const [captchToken, setCaptchToken] = useState(null)

    const validationSchema = Yup.object().shape({
        patientName: Yup.string().required('Patient Name is required'),
        dob: Yup.date().required('Date of Birth is required').nullable(),
        mobile: Yup.string()
            .required('Phone Number is required')
            .matches(/^[0-9]{10}$/, 'Phone Number must be exactly 10 digits'),
        gender: Yup.string().required('Gender is required'),
        selectPerson: Yup.string().required("This field is required"),

        // requestedPeriod: Yup.string().required('Date & Time Choice is required'),
        // Add validation for other fields as needed
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (ibmId) {
            setShow(2);
        }
    }, []);

    const handleChangePhone = (e) => {
        setPhoneNumber(e.target.value?.replace(/\D/g, "")?.slice(0, 10))
        setAlertmessage('')
        setPhoneError('')

    }
    const handleChangeOtp = (e) => {
        setVerifyOtp(e.target.value?.replace(/\D/g, "")?.slice(0, 5))
        setAlertmessage('')
    }


    useEffect(() => {
        // if (usertoken && ibmId) {
        //     dispatch(getPatientdatafromibm(usertoken, ibmId))
        // }
        setPhoneNumber(patientDetails?.telecom?.[0]?.value || '')
    }, [patientDetails])

    const handleRecaptchaChange = (value) => {
        setCaptchToken(value)
        setAlertmessage('')
    }
    const handleSendOtp = () => {

        const data = {
            "id": randomId,
            "phoneNumber": `+91${phoneNumber}`,
            "messagetype": "OTP",
            "apptype": "Paramitha Appointment Booking",
            "email": ""
        }

        axios.get(`${URL.check}/check/validMobileNumber/${phoneNumber}`).then(
            res => {
                if (captchToken) {
                    setLoading(true)

                    axios.post(`${URL.sendOtpAppointment}`, data, {
                        headers: {
                            'Usertoken': `Bearer ${captchToken}`
                        }
                    }).then(res => {
                        if (res.status === 200 || res.status === 201) {
                            setAlertmessage('OTP send successfully')
                            setAlertstatus('success')
                            setVerifyOpen(true)
                            setRequestOpen(false)
                        }
                    }).catch((error) => {
                        // console.log(error?.response?.data?.msg)
                        const errorMessage = error.response ? error.response.data.msg : "An error occurred while sending OTP.";

                        setAlertmessage(errorMessage)
                        setAlertstatus('error')
                    }).finally(() => {
                        setLoading(false)
                    })
                } else {
                    setAlertmessage('Please verify the reCAPTCHA!')
                    setAlertstatus('error')
                }

            }).catch(error => {
                if (error.response && error.response.status === 404) {
                    setPhoneError('Invalid Mobile Number')
                }
            })
    }

    const handleVerifyOtp = async () => {
        // setShow(2)

        const data = {
            "phoneNumber": `+91${phoneNumber}`,
            "otp": verifyOtp,
            "email": "",
            "realm": 'parimitha',
            "organizationId": selectedBranchId

        }
        setLoading(true)
        await axios.post(`${URL.verifyOtpAppointment}`, data, {
            headers: {
                "Usertoken": `True`,
            }
        }).then(res => {
            if (res.status === 200 || res.status === 201) {
                setAlertmessage('OTP verified successfully')
                setAlertstatus('success')
                setShow(2)
            }

        }).catch((err) => {
            // console.log(err, "errr")
            if (err.response.status === 500) {
                setAlertmessage('Internalserver error')
            } else {
                setAlertmessage(err.response.data.msg)
                setAlertstatus('error')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleCancel = () => {
        // setPhoneNumber('')
        setCaptchToken('')
        setVerifyOtp('')
        setAlertmessage('')
        setIsUrgentCareOpen(false)
        setVerifyOpen(false)
    }

    return (
        <div>
            < CommonDialog maxWidth={show === 1 ? 'sm' : 'md'} onClose={show === 1 ? () => {
                handleCancel()
            } : () => { }}

                open={open} sx={{
                    // zIndex: 99999,
                    '.MuiDialog-paper': {
                        // bottom: { md: '0px' },
                        top: { xs: show === 1 ? '0px' : '', sm: show === 1 ? '0px' : '', md: show === 1 ? '-65px' : 20 }
                    }
                }} >

                {
                    show === 1 ? (
                        <>
                            <DialogTitle sx={{ p: { xs: '0px 25px', md: "10px 40px 0px 40px" }, }}>
                                <div className='text-center text:xl md:text-2xl font-medium'>Urgent Care Appointments</div>
                                <div className='text-xs text-center font-medium text-[#E44190]'>
                                    @ {selectedbranch?.name || 'Paramitha Hospitals'} </div>
                                <hr style={{ margin: '10px 0px' }} />
                            </DialogTitle>
                            <DialogContent>
                                < div className='px-2'>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>

                                            <div >
                                                <Box>
                                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Mobile Number</label>
                                                </Box>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    type={'text'}
                                                    value={phoneNumber}
                                                    disabled={verifyOpen}
                                                    onChange={handleChangePhone}
                                                    placeholder="Enter Phone number"
                                                    sx={{
                                                        width: '100%', mt: "5px",
                                                        ".MuiInputBase-root": {
                                                            borderRadius: '7px',
                                                            // backgroundColor: 'whitesmoke',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#59D8FA',
                                                            },
                                                        },
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                +91
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                {phoneError && (
                                                    <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                        {phoneError}
                                                    </Typography>
                                                )}
                                            </div>


                                            {verifyOpen &&
                                                < div className='mt-1'>
                                                    <Box>
                                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Verify OTP</label>
                                                    </Box>
                                                    <CustomTextField
                                                        // label="Display name"
                                                        placeholder="Enter OTP Here"
                                                        type={'text'}
                                                        borderColor="#59D8FA"
                                                        borderRadius="7px"
                                                        name="verifyOtp"
                                                        value={verifyOtp}
                                                        onChange={handleChangeOtp}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                handleVerifyOtp()
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }

                                        </Grid>

                                    </Grid>
                                </div>

                                {
                                    verifyOpen ? null :
                                        <Box sx={{ mt: 4, pl: "15px", transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                            <ReCAPTCHA
                                                sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                                onChange={handleRecaptchaChange}
                                                size="normal"
                                            // style={{ transform: 'scale(0.9)' }}
                                            />
                                        </Box>
                                }
                            </DialogContent >
                            <Divider />
                            <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div>
                            <DialogActions sx={{ m: "5px 20px", justifyContent: 'space-between' }}>

                                <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={handleCancel} >Cancel</Button>
                                <OutlinedButton text={verifyOpen ? "Verify Otp" : "Send Otp"} width="150px" height="32px" borderColor="#1B5775" color="#1B5775" borderRadius="7px" disabled={verifyOpen ? !verifyOtp : phoneNumber.length < 10} endIcon={loading ? <CircularProgress size={'small'} color="inherit" /> : null} onClick={verifyOpen ? handleVerifyOtp : handleSendOtp} />

                            </DialogActions>
                        </>
                    ) : show === 2 ? (
                        <UrgentcareServices selectedbranch={selectedbranch} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} handleClose={handleClose} setShow={setShow} setVerifyOpen={setVerifyOpen} handleCancel={handleCancel} />
                    ) : null
                }

            </CommonDialog >
        </div >
    )
}

export default UrgentCarePopup