"use client";
import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InfoIcon from "@mui/icons-material/Info";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import MapComp from "./mapComp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import patienteducationimg from "../../assets/images/patient Education.svg";
import FAQimg from "../../assets/images/FAQ'S.svg";

import { makeStyles } from "@mui/styles";
import { Add } from "@mui/icons-material";
import { locations } from "../../shared/constants";
import URL from "../../services/api_url";
import axios from "axios";
import BranchUrl from "../../services/api_branch_url";

const useStyles = makeStyles((theme) => ({
  customImageClass: {
    width: "600px",
    height: "600px",
  },
  btn: {
    color: "#000000",
    opacity: 1,
    textTransform: "capitalize",
  },
  heading: {
    fontSize: "18px",
  },
  subheading: {
    fontSize: "14px",
  },
}));

const AboutComp = () => {
  const [orgInfo, setOrgInfo] = useState();
  const classes = useStyles();
  const DocData = [
    {
      title: "Patient Education",
      description: "Article & resource",
      // icon: <InfoIcon style={{ color: "#000000" }} />,
      // imgSrc: "/images/patient%20Education.svg",
      imgSrc: patienteducationimg,
    },
    {
      title: "Life Health Events",
      description: "Ask doctors,view interview podocast",
      icon: <YouTubeIcon />,
      // imgSrc: "/images/patient%20Education.svg",
      imgSrc: patienteducationimg,
    },
    {
      title: "FAQ'S",
      description: "Find Answers for common Questions ",
      icon: <ContactSupportIcon />,
      // imgSrc: "/images/FAQ'S.svg",
      imgSrc: FAQimg,
    },
  ];
  const imageWidth = 200;

  const dynamicImages = [
    {
      imgpath: "/images/doctorimg.jpg",
    },
    {
      imgpath: "/images/doctorimg.jpg",
    },
    {
      imgpath: "/images/doctorimg.jpg",
    },
  ];

  const facilityinformation = [
    // {
    //   title: "07  +",
    //   description: "facilities",
    // },
    {
      title: "100 +",
      description: "doctors",
    },
    {
      title: "450  +",
      description: "beds",
    },
    {
      title: "3000 +",
      description: "Deliveries",
    },
  ];
  const baseurl = BranchUrl();

  useEffect(() => {
    if (process.env.REACT_APP_PARAMITHA_ID) {
      axios
        .get(
          // `${baseurl.fhir_auth}Location?organization=${process.env.REACT_APP_PARAMITHA_ID}`,
          `${baseurl.fhir_tenant_orgId}Location`,
          {
            headers: {
              "X-FHIR-TENANT-ID": "parimitha",
              Realm: "parimitha",
              "X-FHIR-DSID": `fhir${"parimitha"}`,
              "Cache-Control": "no-cache",
            },
          }
        )
        .then((res) => {
          setOrgInfo(res?.data);
          // console.log(res?.data)
        })
        .catch((err) => console.log(err));
    }
  }, [process.env.REACT_APP_PARAMITHA_ID]);

  return (
    <Box sx={{ p: { xs: "10px", sm: "0px" } }}>
      <Typography
        level="h1"
        fontSize={30}
        sx={{
          textAlign: "center",
          fontWeight: "500",
          color: "#000000",
          textTransform: "capitalize",
          // marginTop: "25px",
          color: "#E54290",
        }}
      >
        About paramitha hospitals
      </Typography>
      <Typography
        level="h2"
        fontSize="16px"
        sx={{
          textAlign: "center",
          //   fontWeight: "bold",
          color: "#333",
          opacity: "1",
          textTransform: "capitalize",
        }}
      >
        Advanced healthcare facilities now in your neighborhood
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          p: {
            xs: "0px",
            sm: "10px 10px 10px 25px",
            md: "10px 65px 10px 90px",
          },
          mt: "10px",
        }}
        justifyContent={"center"}
        textAlign={"center"}
      >
        <Grid item xs={12} sm={10} md={10}>
          <Typography variant="body1">
            Since 2004, Paramitha Hospitals is transforming healthcare for
            mother and child. We provide high-quality healthcare for pregnant
            mothers, newborns and children covering every base from Gynecology
            and Maternal care to Pediatrics.
          </Typography>
        </Grid>
      </Grid>
      <Grid justifyContent={"center"} container>
        <Grid item xs={12} sm={8} md={8} alignItems={"center"}>
          {/* <Image
            src="/images/doctorimg.jpg"
            width={400}
            height={400}
            
          /> */}
          <Box sx={{ mt: "20px" }}>
            <MapComp
              locations={locations}
              orgInfo={orgInfo}
            // center={center} zoom={zoom}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container mt={3} justifyContent={"center"} textAlign={"center"}>
        <Grid item xs={12} sm={10} md={10}>
          <Typography variant="body1">
            Our facilities are equipped with state-of-the-art level-III neonatal
            and pediatric intensive care units complimented by our expert
            physicians who come with decades of experience to provide the best
            care for you and your bundle of joy.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} mt={3}>
        <Grid item xs={12} sm={10} md={10}>
          <Grid container justifyContent={"space-between"} spacing={2}>
            {facilityinformation.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                key={index}
                justifyContent={"center"}
                sx={{ p: { md: "20px" }, display: { xs: 'flex', md: 'block' } }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    background: item.description === "beds" ? '#FDF3F7 0% 0% no-repeat padding-box' : '#E44190 0% 0% no-repeat padding-box',
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: '8px',
                    opacity: 1,
                    width: '220px',
                    height: '200px',
                    textAlign: 'center',
                    color: item.description === "beds" ? "#E44190" : '#FFFFFF'
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: '48px',
                    }}
                  >
                    {" "}
                    {item.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ textTransform: "capitalize", mt: "2px", fontSize: '40px' }}
                  >
                    {item.description}
                  </Typography>
                </div>
              </Grid>
            ))}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderColor: " #B9CBD4",
                  textTransform: "capitalize",
                  color: "#1D5775",
                  fontSize: "18px",
                  borderRadius: "10px",
                  // ml: { md: "20px" },
                  height: "35px",
                }}
                endIcon={<ArrowForwardIosIcon sx={{ color: "#1D5775" }} />}
              >
                Our services
              </Button>
            </div> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutComp;
