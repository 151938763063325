"use client";
import React, { useEffect, useState } from 'react'
import { Card, Grid, Paper, TextField, Typography, Divider, Checkbox, Box } from '@mui/material';
import DoctorsList from './doctorslist';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { SpecialistTab } from '../../shared/constants';
import StethIcon from '../../assets/images/stethoscope.svg';
import BranchComponent from '../BranchComponent';
import axios from 'axios';
import { getDoctorSpectilities } from '../../redux/actions/actions';
import URL from '../../services/api_url';
import BranchUrl from '../../services/api_branch_url';

const DoctorPage = () => {
  // const { data: session, status } = useSession();
  const dispatch = useDispatch()
  const urls = BranchUrl()
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
  const [specialitiList, setSpecialitiList] = useState([])
  const [searchSpecaility, setSearchSpeciality] = useState('')

  const [checkSpecialities, setCheckSpecialities] = useState([])
  // const branchName = useSelector((state) => state.branch.branch);
  const branchName = selectBranchInfo?.name
  const orgId = selectBranchInfo?.id
  useEffect(() => {
    const getSpecialities = async () => {
      const res = await getDoctorSpectilities(urls)
      setSpecialitiList(res?.entry)
    }
    getSpecialities()
  }, [])
  const handleSearchSpeciality = (e) => {
    setSearchSpeciality(e.target.value)
  }
  const handleOnChangeCheck = async (e, val) => {
    if (e.target.checked) {
      setCheckSpecialities([...checkSpecialities, val?.resource?.specialty?.[0]?.coding?.[0]?.code]);
    } else {
      setCheckSpecialities(checkSpecialities.filter(id => id !== val?.resource?.specialty?.[0]?.coding?.[0]?.code));
    }
    // try {
    //   const res = axios.post(`${URL.paramitha_v1}PractitionerRole/Practitioner/List`, [...checkSpecialities], {
    //     headers: {
    //       'X-FHIR-DSID': 'fhirparimitha'`,
    //       'X-FHIR-TENANT-ID': 'parimitha',
    //       'Realm': 'parimitha',
    //     }
    //   })
    //   console.log(res, "raja")
    // } catch (error) {

    // }

  }
  // console.log(checkSpecialities, "speacccccccccc")
  const filteredSpecialities = specialitiList?.filter(item => item?.resource?.specialty?.[0]?.coding?.[0]?.display.toLowerCase().includes(searchSpecaility.toLowerCase()))
  // location.name.toLowerCase().includes(searchBranch.toLowerCase())

  // console.log(specialitiList, "speciality")
  // useEffect(() => {
  //   if (
  //     status == "unauthenticated" 
  //     // || (status == "authenticated" && !session.roles?.includes("admin"))
  //   ) {
  //     router.push("/unauthorized");
  //     router.refresh();
  //   }
  // }, [session, status, router]);
  return (
    <Box sx={{ p: { xs: '0px 10px', lg: '0px 70px' } }} >
      <Grid className='px-8'>
        <Grid className='text-lg sm:text-xl md:text-2xl lg:text-3xl  font-semibold text-center pt-4 ' style={{ color: '#E54290' }}>Find Doctor @ {branchName ? `${branchName}` : 'Paramitha Hospitals'}</Grid>
        <Grid container spacing={1} pt={4}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            {!branchName &&
              <BranchComponent />
            }

            <Grid pt={branchName ? 0 : 2}>
              <Paper elevation={3} className=" cursor-pointer" sx={{ p: "10px 15px" }} >
                <div className='text-lg font-semibold pb-2 flex items-center'>
                  <img src={StethIcon} width={20} height={20} alt="stethoscope"
                    sx={{ fontSize: "16px", mr: "5px" }} /> &nbsp; Speciality
                </div>
                <TextField id="standard-basic" value={searchSpecaility} onChange={handleSearchSpeciality} fullWidth label="" placeholder='Search Speciality here' variant="standard" size='small'
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }} />
                <div className='mt-1'>
                  <Grid >
                    {filteredSpecialities && filteredSpecialities.length > 0 ? (
                      filteredSpecialities.map((item, index) => (
                        <Grid key={index} className="cursor-pointer flex items-center">
                          <Checkbox onChange={(e) => handleOnChangeCheck(e, item)} />
                          <div className='text-small font-medium'>
                            {item?.resource?.specialty?.[0]?.coding?.[0]?.display || 'No Display Name'}
                          </div>
                        </Grid>
                      ))
                    ) : (
                      <div className='text-medium mt-1 text-center'>No data available</div>
                    )}

                  </Grid>
                </div>
              </Paper>
            </Grid>

          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            {/* <div className='text-3xl font-semibold pt-2 hidden md:block ' style={{color:'#E54290'}}>Find Doctor @ Paramitha Hospitals{branchName ? `, ${branchName}` : ''}</div> */}
            <DoctorsList />
          </Grid>

        </Grid>
      </Grid>
    </Box>
  )
}

export default DoctorPage;