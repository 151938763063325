import React, { useState } from 'react'
import { Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import StethIcon from '../../assets/images/stethoscope.svg';
import teleIcon from '../../assets/images/Telemedicine.svg';
import YouTubeIcon from '../../assets/images/Icon fa-brands-youtube.svg'
import InstagramIcon from '../../assets/images/Icon corebrands-instagram.svg'
import TwiterIcon from '../../assets/images/Icon fa-brands-x-twitter.svg'
import LinkedInIcon from '../../assets/images/Icon corebrands-linkedin.svg'
import RequestIcon from '../../assets/images/referral.svg'
import { BiSolidPhone } from "react-icons/bi";
import { CiLocationOn, CiMail } from "react-icons/ci";
import CommunicationImg from '../../assets/images/Communication-language.svg';
import ContainedButton from '../atoms/commonbutton';
import { FaXTwitter } from 'react-icons/fa6';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { IoLogoYoutube } from 'react-icons/io';
import HealthCareRequests from '../doctors/healthcareRequests';
import { useSelector } from 'react-redux';
import healthServiceIcon from '../../assets/healthcareprograms/Healthcare Service 1.svg';
import moment from 'moment';


function HealthServiceContacts({ ContactInfo, deptData, deptImg, timings, setTimings, WorkingDays }) {
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    const [open, setOpen] = useState(false)
    const [activeDay, setActiveDay] = useState(null);


    return (
        <div>
            {/* <div className='font-semibold text-lg '>Contact</div> */}
            {/* <Grid container>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} className='p-2 mt-1'>
                        <div className='text-[20px] font-medium'>Healthcare Service</div>
                        <Stack spacing={0.5}>
                            <Grid container>
                                <Grid item md={3}>
                                    <span className='text-[14px] font-medium'>Category</span>
                                </Grid>
                                <Grid item md={7}>
                                    <span>
                                        {Array.isArray(deptData?.category) && deptData?.category.map((item, index) => (
                                            <div key={index} className='text-[14px]'>: {item?.coding?.[0]?.display}</div>
                                        ))}
                                    </span>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item md={3}>
                                    <span className='text-[14px] font-medium'>Service Type</span>
                                </Grid>
                                <Grid item md={7}>
                                    <span>
                                        {Array.isArray(deptData?.type) && deptData?.type.map((item, index) => (
                                            <div key={index} className='text-[14px]'>: {item?.coding?.[0]?.display}</div>
                                        ))}
                                    </span>
                                </Grid>
                            </Grid>
                            <div className='text-[14px] flex font-medium'>
                                <span className='pr-3'>Service Type:</span>
                                <span>
                                    {Array.isArray(deptData?.type) && deptData?.type.map((item, index) => (
                                        <div key={index} className='text-[14px]'>{item?.coding?.[0]?.display}</div>
                                    ))}
                                </span>
                            </div>
                            <div className='flex gap-1'>
                                <img src={StethIcon} width={28} alt="Stethoscope Icon" />
                                <div className='flex gap-1 flex-wrap items-center'>
                                    {Array.isArray(deptData?.specialty) && deptData?.specialty.map((item, index) => (
                                        <div key={index} className='text-sm rounded-full px-2' style={{ background: '#EBEBEB' }}>
                                            {item?.coding?.[0]?.display}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='text-[14px] flex flex-wrap gap-2 mt-1'>
                                <img src={CommunicationImg} width={14} alt="Communication Icon" />
                                <div className='flex flex-wrap gap-1'>
                                    <span className='font-medium text-[14px]'>Communicate in</span>
                                    <span style={{ color: '#092184B1' }} className='flex flex-wrap gap-1'>
                                        {Array.isArray(deptData?.communication) && deptData?.communication.length > 0 ?
                                            deptData?.communication.map((item, index) => (
                                                <div key={index}>{item?.coding?.[0]?.display}</div>
                                            )) : '-------'
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className='text-[14px] font-medium mt-1'>
                                Referral {Array.isArray(deptData?.referralMethod) && deptData?.referralMethod.length > 0 ? '' : 'not'} Required
                            </div>
                        </Stack>
                        <div className='text-[14px] pt-2 font-medium'>Register & Book Appointment to start your Wellness journey</div>
                        <Grid container spacing={2} justifyContent={"center"} pt={1}>
                            <Grid item>
                                <ContainedButton
                                    variant="outlined"
                                    onClick={() => setOpen(true)}
                                    startIcon={<img src={RequestIcon} width={'18px'} alt="Request Icon" />}
                                    text="Request Appointment"
                                    fontSize={{ xs: "10px", sm: "10px", md: "12px", lg: "14px" }}
                                    borderRadius="10px"
                                    bgcolor="#E44190"
                                    color="#205072"
                                    height="32px"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={3} sx={{ px: 2, py: 1, mt: 2, mb: { xs: 3, sm: 3, md: 3, lg: 3 } }}>
                        <Grid container>
                            <Grid item xs={4} sm={2} md={5} mt={'10px'}>
                                <div className='flex gap-3 items-center'>
                                    <BiSolidPhone style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} />
                                    <span>Phone</span>
                                </div>
                            </Grid>
                            <Grid item xs={8} sm={10} md={7} mt={'10px'}>
                                <ContactInfo contactDetails={deptData?.telecom} system={'phone'} />
                            </Grid>
                            <Grid item xs={4} sm={2} md={5} mt={'10px'}>
                                <div className='flex gap-3 items-center'>
                                    <CiMail style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} />
                                    <span>Email &nbsp;</span>
                                </div>
                            </Grid>
                            <Grid item xs={8} sm={10} md={7} mt={'10px'}>
                                <ContactInfo contactDetails={deptData?.telecom} system={'email'} />
                            </Grid>
                            <Grid container alignItems={"center"} pt={1}>
                                <Grid item xs={4} sm={2} md={5} alignItems={"center"}>
                                    <span className='flex gap-3 items-center ml-4'>
                                        <span>Follow &nbsp;</span>
                                    </span>
                                </Grid>
                                <Grid item xs={8} sm={10} md={7}>
                                    <Stack direction="row" spacing={2}>
                                        <FaXTwitter style={{ height: 24, width: 24, color: '#E44190' }} />
                                        <FaInstagram style={{ height: 24, width: 24, color: '#E44190' }} />
                                        <IoLogoYoutube style={{ height: 24, width: 24, color: '#E44190' }} />
                                        <FaLinkedin style={{ height: 24, width: 24, color: '#E44190' }} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid> */}

            <div className='py-3'>
                <Grid container alignItems={'center'}>
                    <Grid item xs={12} md={8.5} lg={10} p={"10px 0px"}>
                        <div className='text-xl font-medium'>Availability</div>

                        <div style={{ display: "flex", gap: "5px", margin: "12px 0px", flexWrap: 'wrap' }}>
                            {WorkingDays?.length > 0 ? WorkingDays?.map((item, i) => {
                                return (
                                    <Button
                                        key={i}
                                        variant="contained"
                                        onClick={() => {
                                            setTimings(item);
                                            setActiveDay(i);
                                        }}
                                        sx={{
                                            minWidth: "50px",
                                            minHeight: '30px',
                                            border: '1px solid #E54290',
                                            background:
                                                activeDay === i && activeDay !== null
                                                    ? "#480223"
                                                    : item.today && activeDay === null
                                                        ? "#480223"
                                                        : !item.allDay && !item.hasOwnProperty("workingHours")
                                                            ? "#fff"
                                                            : "#fff",
                                            padding: "0px 7px",
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: activeDay === i && activeDay !== null
                                                    ? "#fff"
                                                    : item.today && activeDay === null
                                                        ? "#fff"
                                                        : !item.allDay && !item.hasOwnProperty("workingHours")
                                                            ? "#E54290"
                                                            : "#E54290",
                                                textTransform: "capitalize",
                                            }}
                                        // style={customStyles}
                                        >
                                            {item.day}
                                        </Typography>
                                    </Button>
                                );
                            }) : <div>No Slots Available</div>}
                        </div>

                        {timings && timings?.allDay ? (
                            <Typography sx={{ textTransform: 'capitalize' }}>
                                {timings.day} : 24 Hours - All Day
                            </Typography>
                        ) : timings?.allDay == false ? (
                            <Typography variant="body2" className="timing">
                                No Slot avalable
                            </Typography>
                        ) : (
                            timings?.workingHours &&
                            timings?.workingHours?.map((item, ind) => {
                                return (
                                    <Typography
                                        key={ind}
                                        mb={2}
                                        sx={{ marginBottom: "5px !important" }}
                                        variant="body2"
                                        className="timing"
                                    >
                                        {moment(item?.openingTime, "hh:mm").format("HH:mm A")}
                                        &nbsp;&nbsp;-&nbsp;&nbsp;
                                        {moment(item?.closingTime, "hh:mm").format("HH:mm A")}
                                    </Typography>
                                );
                            })
                        )}

                    </Grid>
                    {deptData.appointmentRequired &&

                        <Grid item xs={12} md={3.5} lg={2}>
                            <ContainedButton
                                variant="outlined"
                                onClick={() => setOpen(true)}
                                startIcon={<img src={RequestIcon} width={'18px'} />}
                                text="Request Appointment"
                                // height="30px"
                                fontSize={{ xs: "14px", sm: "14px", md: "14px", lg: "14px", }}
                                borderRadius="10px"
                                bgcolor="#E44190"
                                color="#205072"

                            />
                        </Grid>
                    }
                </Grid>
            </div>

            <Divider />
            <div className='py-2'>
                <Grid container alignItems={'center'}>

                    <Grid item xs={12} md={9} lg={10}>
                        <div className='text-xl font-medium'>Contact Information</div>

                        <div className='flex gap-3  items-center mt-1' >
                            {/* <BiSolidPhone style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} /> */}
                            <FiPhoneCall />
                            <div>{deptData?.telecom?.[0]?.value || 'Not Available'}</div>
                        </div>


                        <div className='flex gap-3  items-center mt-0.5' >
                            <AiOutlineMail />
                            {/* <CiMail style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} /> */}
                            <div>
                                {deptData?.telecom?.find(item => item?.system === 'email')?.value || 'Not Available'}
                            </div>                        </div>
                        {/* <div className='flex gap-3  items-center' >
                            <CiLocationOn style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} />
                        <div>{deptData?.telecom?.[1]?.value}</div>
                        </div> */}



                    </Grid>

                    <Grid item xs={12} md={3} lg={2}>
                        <Stack direction="row" spacing={2}>
                            <FaXTwitter style={{ height: 34, width: 34, color: '#E44190' }} />
                            <FaInstagram style={{ height: 34, width: 34, color: '#E44190' }} />
                            <IoLogoYoutube style={{ height: 34, width: 34, color: '#E44190' }} />
                            <FaLinkedin style={{ height: 34, width: 34, color: '#E44190' }} />

                        </Stack>
                    </Grid>
                </Grid>

            </div>
            <HealthCareRequests open={open} setOpen={setOpen} selectedbranch={selectedbranch} deptData={deptData} serviceData={deptData} deptImg={deptImg || healthServiceIcon} comment={deptData?.comment} />

        </div>
    )
}

export default HealthServiceContacts;