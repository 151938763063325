import React, { useEffect, useState } from 'react';
import {Tabs, Tab, Box, Paper, Typography, Grid, Button, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, Divider,IconButton,} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForward, KeyboardArrowLeft, KeyboardArrowRight, LensOutlined, LensRounded } from '@mui/icons-material';
import { locationJson } from '../../../shared/constants';
import axios from 'axios';
import URL from '../../../services/api_url';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PostData } from './PostData';
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import LatestUpdates from './LatestUpdates';
import { useSelector } from 'react-redux';



const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div
      className="unique-button-group-container"
      style={{ position: "absolute", top: "0", right: "0" }}
    >
      <IconButton
        aria-label="arrow-left"
        className={`carousel-arrow-button ${currentSlide === 0 ? "disable" : ""
          }`}
        onClick={() => previous()}
      >
        <KeyboardArrowLeft fontSize="inherit" />
      </IconButton>
      <IconButton
        aria-label="arrow-right"
        className="carousel-arrow-button"
        onClick={() => next()}
      >
        <KeyboardArrowRight fontSize="inherit" />
      </IconButton>
    </div>
  );
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  return (
    <li
      style={{
        cursor: "pointer",
        color: active ? "#E54290" : "#FDF7F3",
        marginTop: "10px",
      }}
      onClick={() => onClick()}
    >
      {active ? (
        <LensRounded style={{ fontSize: "14px" }} />
      ) : (
          <LensOutlined style={{ fontSize: "14px", color: "#E54290" }} />
      )}
    </li>
  );
};
const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    borderRadius: '8px 8px 0 0',
  },
  tab: {
    // minHeight: '40px',
    textTransform: 'capitalize !important',
    fontSize: '18px !important',
    '&.Mui-selected': {
      color: '#E54290 !important',
      fontWeight: 500,
      backgroundColor: '#FFFFFF',
      borderRadius: "20px",
      fontSize: '18px',
      // lineHeight: '21px',
      fontWeight: 'normal'

    },
   
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    gap: '10px',
    backgroundColor: '#FDF3F7',
    height: '84px',
    border: '0.5px solid #E54290',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease, border 0.3s ease',
    '&:hover': {
      border: 'none',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
    },
  },
}));


const HealthComp = () => {
  const classes = useStyles();
  const branchList = useSelector((state) => state.login.branchLists);
  const [value, setValue] = React.useState(0);
  const [keyState, setKeyState] = React.useState("");
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const limit = 25;
  
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const pageIds = branchList.map(branch => branch.pageId).join(',');

    useEffect(() => {
    
      const fetchPosts = async () => {
          try {
              // Assuming you have an array of branch data with pageId

              // Now pass the pageIds as a query parameter
              if(pageIds){
              const response = await axios.get(
                  `${URL.post}feed/getpostbypageid/${pageIds}?limit=25&offset=0`
              );
              setPosts(response?.data?.results || []);
            }
          } catch (error) {
              console.error("Error fetching posts:", error);
              // Handle error as needed
          } finally {
              setLoading(false);
          }
      };

      fetchPosts();
  }, [pageIds]);


 

  return (
<div className='mt-3'>
     <Typography level="h1"sx={{ textAlign: "center", fontWeight: 500, color: "#E54290", fontSize: { xs: 24, md: 30 }}}>
       Health Information & Education
      </Typography>
      <Typography
        level="h2"
        sx={{
          textAlign: "center",
          //   fontWeight: "bold",
          color: "#000000",
          opacity: "1",
          textTransform: "capitalize",
          fontSize: { xs: 16, md: 18 },
          fontWeight:'lighter'
        }}
      >
        Advices & opinions from experts, find new events, articles & more
      </Typography>
    <Box sx={{ bgcolor: '#FDF3F7',p:'30px 10px',mt:2 ,width:'100%'}}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          className={classes.tabsContainer}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ sx: { display: "none" } }}
        >
            <Tab label="Latest Updates" className={classes.tab} sx={{'&.MuiTab-root':{
            height:"0px !important",
            } }} />
          <Tab label="Upcoming Events" className={classes.tab} />
          <Tab label="New Articles" className={classes.tab} />
          <Tab label="Highlights" className={classes.tab} />
          <Tab label="Offers" className={classes.tab} />
        </Tabs>
      </Box>
{
  value===0&&
  <div className='pb-5'>
    <LatestUpdates posts={posts} loading={loading} ButtonGroup={ButtonGroup} CustomDot={CustomDot} responsive={responsive} /> 
    </div>
}

{
  value===1&&
          <div className='flex justify-center text-center mt-2'>Upcoming Events</div>
}

{
  value===2&&
          <div className='flex justify-center text-center mt-2'>New Articles</div>
}

{
  value===3&&
<div className='flex justify-center text-center mt-2'> Highlights</div>
}

{
  value===4&&
  <div className='flex justify-center text-center mt-2'>Offers</div>
} 
    </Box>
    <div className='mt-2 flex justify-center'>
        <Link to="/healthinformation">
        <CardActions>
          <Button variant="outlined"
              sx={{
                borderColor: "#E54290", textTransform: "capitalize", color: '#E54290', '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                width: { xs: '200px', md: 255 }, borderRadius: "10px", mt: 2, height: { xs: 44, md: "54px" }
              }}
          >
              <Typography sx={{ fontSize: { xs: '14px', md: "18px", fontWeight: 500 } }}>            
                 Health Information
</Typography>

            <ArrowForward />
          </Button>
        </CardActions>
      </Link>
      </div>
    </div>
  );
};

export default HealthComp;

// import {
//   Box,
//   Button,
//   Card,
//   CardActions,
//   CardContent,
//   CardHeader,
//   CardMedia,
//   CircularProgress,
//   Divider,
//   Grid,
//   IconButton,

//   Paper,
//   Typography,
// } from "@mui/material";
// import axios from "axios";
// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
// import { LensOutlined, LensRounded } from "@mui/icons-material";
// import { PostData } from "./PostData";
// import URL from "../../../services/api_url";
// import { locationJson } from "../../../shared/constants";
// // import "./healthcom.css";

// // const UserId = "1425645329";
// // console.log(UserId, "UserId");

// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//     slidesToSlide: 3, // optional, default to 1.
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//     slidesToSlide: 2, // optional, default to 1.
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//     slidesToSlide: 1, // optional, default to 1.
//   },
// };

// const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
//   const {
//     carouselState: { currentSlide },
//   } = rest;
//   return (
//     <div
//       className="unique-button-group-container"
//       style={{ position: "absolute", top: "0", right: "0" }}
//     >
//       <IconButton
//         aria-label="arrow-left"
//         className={`carousel-arrow-button ${currentSlide === 0 ? "disable" : ""
//           }`}
//         onClick={() => previous()}
//       >
//         <KeyboardArrowLeftIcon fontSize="inherit" />
//       </IconButton>
//       <IconButton
//         aria-label="arrow-right"
//         className="carousel-arrow-button"
//         onClick={() => next()}
//       >
//         <KeyboardArrowRightIcon fontSize="inherit" />
//       </IconButton>
//     </div>
//   );
// };

// const CustomDot = ({ onMove, index, onClick, active }) => {
//   return (
//     <li
//       style={{
//         cursor: "pointer",
//         color: active ? "#134265" : "#fff",
//         marginTop: "10px",
//       }}
//       onClick={() => onClick()}
//     >
//       {active ? (
//         <LensRounded style={{ fontSize: "14px" }} />
//       ) : (
//         <LensOutlined style={{ fontSize: "14px", color: "#000" }} />
//       )}
//     </li>
//   );
// };

// const HealthComp = () => {
//   const [keyState, setKeyState] = React.useState("");
//   const [posts, setPosts] = React.useState([]);
//   const [loading, setLoading] = useState(false);
//   const [load, setLoad] = useState(false);
//   const limit = 25;

//   useEffect(() => {
//     postAPIS();
//   }, []);

 

//   const postAPIS = async (key) => {
//     const pageIds = locationJson.map(location => location.pageId)?.join(',');
//     console.log(pageIds, 120,)
//     setLoading(true);

//     await axios
//       .get(
//         `${URL.feed}feed/getpostbypageid/${pageIds}?limit=25&offset=0`
//       )
//       .then((res) => {
//         setPosts(res.data.results);
//       })
//       .catch((error) => console.log(error, "6666666666"))
//       .finally(() => {
//         setLoading(false);
//       });
//   };

 

//   return (
//     <Box sx={{ p: { xs: "0px 10px ", sm: "0px", md: "0px" } }}>
//       <Typography
//         level="h1"

//         sx={{
//           textAlign: "center",
//           fontWeight: 500,
//           color: "#E54290",
//           textTransform: "uppercase",
//           fontSize: { xs: 24, md: 30 }
//         }}
//       >
//         Healthcare information
//       </Typography>
//       <Typography
//         level="h2"
//         sx={{
//           textAlign: "center",
//           //   fontWeight: "bold",
//           color: "#000000",
//           opacity: "1",
//           textTransform: "capitalize",
//           fontSize: { xs: 16, md: 18 }
//         }}
//       >
//         Know from Experts,We Priorities Public Health Awareness and Patient
//         Education
//       </Typography>

//       <Box
//         sx={{
//           p: {
//             xs: "5px",
//             sm: "5px 20px",
//             md: "5px 80px",
//           },
//           mt: 1,
//         }}
//       >
//         <Card
//           sx={{
//             p: {
//               xs: "0px",
//               sm: "10px 10px",
//               md: "5px 10px",
//             },
//           }}
//         >
//           <CardContent>
//             <div style={{ position: "relative" }}>
//               <div style={{ display: "flex" }}>
//                 <Typography
//                   sx={{
//                     color: "#000",
//                     fontSize: 18,
//                     fontWeight: 500,
//                   }}
//                 >
//                   Timeline
//                 </Typography>
//               </div>

//               <Carousel
//                 swipeable={false}
//                 draggable={false}
//                 showDots={true}
//                 responsive={responsive}
//                 ssr={true}
//                 keyBoardControl={true}
//                 containerClass="carousel-container-3"
//                 removeArrowOnDeviceType={["tablet", "mobile"]}
//                 customButtonGroup={<ButtonGroup />}
//                 renderButtonGroupOutside={true}
//                 customDot={<CustomDot />}
//                 arrows={false}
//               >
//                 {loading ? (
//                   <CircularProgress
//                     sx={{ display: "block", mx: "auto", mt: 3 }}
//                   />
//                 ) : (
//                   posts.map((post, index) => (
//                     <PostData feedData={post} key={index} />
//                   ))
//                 )}
//               </Carousel>
//             </div>
//           </CardContent>
//           <Divider sx={{ borderBottomWidth: 1, borderRadius: 20, color: "#F7F7F7", }} />
//           <Link to="/homeposts">
//             <CardActions>
//               <Button variant="contained"
//                 sx={{
//                   backgroundColor: "transparent",
//                   color: "#A3A3A3",
//                   boxShadow: "none",
//                   textTransform: "capitalize",
//                   marginX: "auto",
//                   border: "1px solid #A3A3A3",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   "&:hover": {
//                     backgroundColor: "transparent",
//                   },
//                 }}
//               >
//                 Show All Posts
//                 <ArrowRightAltIcon />
//               </Button>
//             </CardActions>
//           </Link>
//         </Card>
//       </Box>
//     </Box>
//   );
// };

// export default HealthComp;
