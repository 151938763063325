import React, { useState } from "react";
import { Box, CardMedia, Typography } from "@mui/material";

function FeedMedia({ docFeed }) {
    const image = docFeed.sourceLink.replace(/,\s*$/, "");
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        // setExpanded(true);
    };
    return (
        <Box sx={{ p: "0px 5px" }}>

            <Typography sx={{ fontSize: 13, p: "0px 5px" }}>

                {docFeed?.description ? (
                    <>
                        {expanded
                            ? docFeed.description
                            : `${docFeed.description.slice(0, 75)}`}
                        {docFeed.description.length > 80 ? (
                            <Typography
                                component={"span"}
                                onClick={handleExpandClick}
                                sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                            >
                                {expanded ? "...see less" : "...see more"}{" "}
                            </Typography>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    <Box sx={{ height: { sm: 63, md: 62, lg: 62 } }}></Box>
                )}
            </Typography>
            <Box sx={{ height: '13px' }}>

            </Box>
            <div >
                {docFeed.sourceLink ? (
                    <Box sx={{ width: "100%" }}>
                        <CardMedia
                            className="object-fill"
                            component="img"
                            // height="194"
                            // sx={{
                            //     height: { xs: "200px", sm: "200px", md: "235px", lg: "230px" },
                            //     //   border: "1px solid red",
                            // }}
                            src={image}
                            alt="Paella dish"
                        />
                    </Box>
                ) : null}
            </div>
        </Box >
    );
}

export default FeedMedia;
