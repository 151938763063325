import PediatricsImage from "../assets/images/pediatrics.svg";
import neonatologyImage from "../assets/images/neonatology.svg";
import pediatricsurgeryImage from "../assets/images/Pediatric Surgery.svg";
import cardiologyImage from "../assets/images/cardiology.svg";
import pulmonologyImage from "../assets/images/pulmonology.svg";
import ENTImage from "../assets/images/ENT.svg";
import DermatologyImage from "../assets/images/Dermatology.svg";
import NeurologyImage from "../assets/images/Neurology.svg";
import EndocrynologyImage from "../assets/images/Endocrynology.svg";
import OrthopedicsImage from "../assets/images/Orthopedics.svg";
import NeurosurgeryImage from "../assets/images/Neurosurgery.svg";
import PsychiatryImage from "../assets/images/Psychiatry.svg";
import OpthamologyImage from "../assets/images/Opthamology.svg";
import dentalImage from "../assets/images/dental.svg";
import nephrologyImage from "../assets/images/nephrology.svg";
import OncologyImage from "../assets/images/Oncology.svg";
import GastroentrologyImage from "../assets/images/Gastroentrology.svg";
// import plasticsurgeryImage from "../assets/images/plastic%20surgery.svg";
import plasticsurgeryImage from "../assets/images/plastic surgery.svg";
import generalImage from "../assets/images/General Medicine.svg";
import OBGImage from "../assets/images/OBG.svg";
import fetusImage from "../assets/images/fetus.svg";
import RadiologyImage from "../assets/images/Radiology.svg";
import PATHOLOGYImage from "../assets/images/PATHOLOGY.svg";
import geneticsImage from "../assets/images/genetics.svg";

import outpatientImage from "../assets/images/outpatient.svg";
import internationalcenterImage from "../assets/images/international centre.svg";
import emergencyImage from "../assets/images/Emergency Dept.svg";
import NICUImage from "../assets/images/NICU.svg";
import pediatricsImage from "../assets/images/pediatrics.svg";
import maternityImage from "../assets/images/maternity care.svg";
import inpatientImage from "../assets/images/inpatient.svg";
import daycareImage from "../assets/images/DayCare Facility.svg";
import surgicalImage from "../assets/images/surgical care.svg";
import deptImage from "../assets/images/dept.svg";
import DiagnosticsImage from "../assets/images/Diagnostics.svg";
import pharmacyImage from "../assets/images/pharmacy meds.svg";
import referalImage from "../assets/images/referral-AND-Transfer.svg";
import patientImage from "../assets/images/patient Education.svg";
import billingImage from "../assets/images/Billing and Insurence.svg";



export const genderOptions = ["male", "female", "other"];


export const DoctorsTab = [
  {
    docname: "Dr Sri Harsha",
    description: "M.B.B.S PEDIATRICES FELLOWSHIP IN FLAP",
    communication: "Communicate in",
    study: "General pediatrices ,Neonatology",
    experience: "Senior Consultant Neonatology & pediatrices",
    address: "Available at ",
    area: "Kompally",
    timings: "Available",
  },
  {
    docname: "Dr G.Dhanraj",
    description: "MBBS, MD, DCH",
    communication: "Communicate in",
    study: "General Pediatrics",
    experience: "Senior Consultant Pediatrician",
    address: "Available at ",
    area: "Kothapet",
    timings: "Available:",
  },
  {
    docname: "Dr D.Giridhar",
    description: "MBBS,DCH",
    communication: "Communicate in",
    study: "General Pediatrics",
    experience: "Consultant Pediatrician",
    address: "Available at ",
    area: "Madhapur",
    timings: "Available:",
  },
  {
    docname: "Dr V.Sharavani",
    description: "M.B.B.S, DNB-PEDIATRICS",
    communication: "Communicate in",
    study: "General pediatrices ,Neonatology",
    experience: "Senior Consultant Neonatology & pediatrices",
    address: "Available at ",
    area: "Kondapur",
    timings: "Available:",
  },
  {
    docname: "Dr A.C. Reddy",
    description: "M.B.B.S, DCH",
    communication: "Communicate in",
    study: "General Pediatrics",
    experience: "Visiting Consultant Pediatrician",
    address: "Available at ",
    area: "Ameerpet",
    timings: "Available:",
  },
  {
    docname: "Dr S. Sree Ram",
    description: "M.B.B.S, MD(Ped), DM (Neonatology)",
    communication: "Communicate in",
    study: "Neonatology",
    experience: "Consultant Neonatologist",
    address: "Available at ",
    area: "Panjagutta",
    timings: "Available:",
  },
];

export const SpecialistTab = [
  {
    title: "General Pediatrics",
    description: "Children and adolescents Care",
    // imgSrc: "/images/pediatrics.svg",
    imgSrc: PediatricsImage,
  },
  {
    title: "Neonatology",
    description: "PostNatal & New Born Care",
    // imgSrc: "/images/neonatology.svg",
    imgSrc: neonatologyImage,
  },
  {
    title: "Pediatrics Surgery",
    description: "PostNatal & New Born Care",
    // imgSrc: "/images/Pediatric%20Surgery.svg",
    imgSrc: pediatricsurgeryImage,
  },
  {
    title: "Pediatric Cardiology",
    description: "Respiratory Care for Childrens",
    // imgSrc: "/images/cardiology.svg",
    imgSrc: cardiologyImage,
  },
  {
    title: "Paediatric Pulmonology",
    description: "Respiratory Care for Childrens",
    // imgSrc: "/images/pulmonology.svg",
    imgSrc: pulmonologyImage,
  },
  {
    title: "Pediatric ENT",
    description: "Ear Nose and Throat Specialist for Children",
    // imgSrc: "/images/ENT.svg",
    imgSrc: ENTImage,
  },
  {
    title: "Pediatric Dermatology",
    description: "Skin specialist for Children",
    // imgSrc: "/images/Dermatology.svg",
    imgSrc: DermatologyImage,
  },
  {
    title: "Paediatric Neurology",
    description: "Neurology and child Brain development",
    // imgSrc: "/images/Neurology.svg",
    imgSrc: NeurologyImage,
  },
  {
    title: "Pediatric Endocrinology",
    description: "Thyroid, Diabetes, growth, puberty in Children",
    // imgSrc: "/images/Endocrynology.svg",
    imgSrc: EndocrynologyImage,
  },
  {
    title: "Pediatric Orthopaedics",
    description: "Bone & Muscle Care",
    // imgSrc: "/images/Orthopedics.svg",
    imgSrc: OrthopedicsImage,
  },
  {
    title: "Paediatric Neuro-surgery",
    description: "Surgical Specialists for Brain and Spine",
    // imgSrc: "/images/Neurosurgery.svg",
    imgSrc: NeurosurgeryImage,
  },
  {
    title: "Pediatric Psychiatry",
    description: "Thyroid, Diabetes, growth, puberty in Children",
    // imgSrc: "/images/Psychiatry.svg",
    imgSrc: PsychiatryImage,
  },
  {
    title: "Pediatric Opthomology",
    description: "Eye care and Vision Children",
    // imgSrc: "/images/Opthamology.svg",
    imgSrc: OpthamologyImage,
  },
  {
    title: "Paediatric Dentistry",
    description: "Teeth and Gum care for Children",
    // imgSrc: "/images/dental.svg",
    imgSrc: dentalImage,
  },
  {
    title: "Pediatric Nephrology",
    description: "kidney and urinary tract Specialist",
    // imgSrc: "/images/nephrology.svg",
    imgSrc: nephrologyImage,
  },
  {
    title: "Pediatric Hemato Oncology",
    description: "Woman and Pregnancy Care",
    // imgSrc: "/images/Oncology.svg",
    imgSrc: OncologyImage,
  },
  {
    title: "Pediatric Gastroenterologist",
    description: "Woman and Pregnancy Care",
    // imgSrc: "/images/Gastroentrology.svg",
    imgSrc: GastroentrologyImage,
  },
  {
    title: "Pediatric Plastic Surgery",
    description: "Woman and Pregnancy Care",
    // imgSrc: "/images/plastic%20surgery.svg",
    imgSrc: plasticsurgeryImage,
  },
  {
    title: "General Medicine",
    description: "Primary Care and Famaily health Physicians",
    // imgSrc: "/images/General%20Medicine.svg",
    imgSrc: generalImage,
  },
  {
    title: "Obstetrics & Gynaecology",
    description: "Woman and Pregnancy Care",
    // imgSrc: "/images/OBG.svg",
    imgSrc: OBGImage,
  },
  {
    title: "Fetal Medicine",
    description: "Diagnosis and Treatment of Foetus",
    imgSrc: "/images/fe/tus.svg",
    imgSrc: fetusImage,
  },
  {
    title: "Pediatric Radiology",
    description: "CT Scan, X-Ray, MRI experts for Children",
    // imgSrc: "/images/Radiology.svg",
    imgSrc: RadiologyImage,
  },
  {
    title: "Pathology",
    description: "Laboratory Tests and Diagnostics Experts",
    // imgSrc: "/images/PATHOLOGY.svg",
    imgSrc: PATHOLOGYImage,
  },
  {
    title: "Medical Geneticist",
    description: "Specialist to Diagnose and treat genetic disorders",
    // imgSrc: "/images/genetics.svg",
    imgSrc: geneticsImage,
  },
];

export const ServicesTab = [
  {
    title: "Out-Patient Consult",
    description: "Primary care & Speciality consultation",
    // imgSrc: "/images/outpatient.svg",
    imgSrc: outpatientImage,

    // imageSrc: "/images/parmitalogo.png",
  },
  {
    title: "International Centre",
    description: "International Patients care Team",
    // imgSrc: "/images/international%20centre.svg",
    imgSrc: internationalcenterImage,
  },
  {
    title: "Emergency Department",
    description: "Simple triage and rapid treatment",
    // imgSrc: "/images/Emergency%20Dept.svg",
    imgSrc: emergencyImage,
  },
  {
    title: "Neonatal ICU",
    description: "Intensive care with Incubation",
    // imgSrc: "/images/NICU.svg",
    imgSrc: NICUImage,
  },
  {
    title: "Pediatric ICU",
    description: "Intensive care unit for Children",
    // imgSrc: "/images/pediatrics.svg",
    imgSrc: pediatricsImage,
  },
  {
    title: "Maternity Care",
    description: "Labour, Delivery & Resuscitation",
    // imgSrc: "/images/maternity%20care.svg",
    imgSrc: maternityImage,
  },
  {
    title: "OIn-patient Admission",
    description: "Stay under Medical Attention",
    // imgSrc: "/images/inpatient.svg",
    imgSrc: inpatientImage,
  },
  {
    title: "Day Care Facility",
    description: "Short term stay for recovery",
    // imgSrc: "/images/DayCare%20Facility.svg",
    imgSrc: daycareImage,
  },
  {
    title: "Surgical Care",
    description: "Surgical Procedures, Post Operative care",
    // imgSrc: "/images/surgical%20care.svg",
    imgSrc: surgicalImage,
  },
  {
    title: "Radiology Services",
    description: "Ultrasound, CT, MRI, X-ray & more",
    // imgSrc: "/images/dept.svg",
    imgSrc: deptImage,
  },
  {
    title: "Diagnostics Services",
    description: "Advanced Bio-chemical & micro",
    // imgSrc: "/images/Diagnostics.svg",
    imgSrc: DiagnosticsImage,
  },
  {
    title: "Pharmacy & Medicines",
    description: "Medications sales & Counselling",
    // imgSrc: "/images/pharmacy%20meds.svg",
    imgSrc: pharmacyImage,
  },
  {
    title: "Referral & Transfer Services",
    description: "In-bound & out-bound patient transfers",
    // imgSrc: "/images/referral-AND-Transfer.svg",
    imgSrc: referalImage,
  },
  {
    title: "Patient Counselling",
    description: "Qualified Experts & education",
    // imgSrc: "/images/patient%20Education.svg",
    imgSrc: patientImage,
  },
  {
    title: "Billing and Insurance",
    description: "Cost estimate, Insurance authorisation..",
    // imgSrc: "/images/Billing%20and%20Insurence.svg",
    imgSrc: billingImage,
  },
];

export const locations = [
  ["chanda nagar", 17.495824645394702, 78.32095001501918],
  ["chintal", 17.487892061502862, 78.44854428029457],
  ["kompally", 17.518422339582578, 78.48276319462754],
  ["kothapet", 17.359816366215018, 78.54491009787584],
  ["medipally", 17.40790559980765, 78.59811972286937],
];

//*development*//

// export const locationJson = [
//   {
//     name: "kothapet",
//     address: {
//       street: "Main Road",
//       area: "Kothapet",
//       city: "Hyderabad",
//       state: "Telangana",
//       pincode: "500035",
//     },
//     id: '18ee72d9665-b5e6270d-85c3-4659-9956-9ef69f126096',
//     tenant: 'parimitha',
//     // tenant: 'pkothapet',
//     pageId: '477'
//   },
//   {
//     name: "kompally",
//     address: {
//       street: "Kompally",
//       area: "Secunderabad",
//       city: "Hyderabad",
//       state: "Telangana",
//       pincode: "500055",
//     },
//     id: `${process.env.REACT_APP_KOMPALLY_ORGID}`,
//     tenant: 'parimitha',
//     pageId: `${process.env.REACT_APP_KOMPALLY_PAGEID}`
//   },
//   {
//     name: "chintal",
//     address: {
//       street: "APHB colony",
//       area: "chintal",
//       city: "Hyderabad",
//       state: "Telangana",
//       pincode: "500054",
//     },
//     id: `${process.env.REACT_APP_CHINTAL_ORGID}`,
//     tenant: 'parimitha',
//     pageId: `${process.env.REACT_APP_CHINTAL_PAGEID}`
//   },
//   {
//     name: "chandanagar",
//     address: {
//       street: "Jawha Colony",
//       area: "Chandanagar",
//       city: "Hyderabad",
//       state: "Telangana",
//       pincode: "500050",
//     },
//     id: '18ee7240353-d527fdd2-685b-478a-94a4-b01cd5d8fcd4',
//     tenant: 'parimitha',
//     pageId: '473'
//   },
//   {
//     name: "medipally",
//     address: {
//       street: "Peerzadiguda",
//       area: "Medipally",
//       city: "Hyderabad",
//       state: "Telangana",
//       pincode: "500039",
//     },
//     id: `${process.env.REACT_APP_MEDIPALLY_ORGID}`,
//     tenant: 'parimitha',
//     pageId: `${process.env.REACT_APP_MEDIPALLY_PAGEID}`
//   },
//   {
//     name: "bachupally",
//     address: {
//       street: "Bollaram Rd",
//       area: "Bachupally",
//       city: "Hyderabad",
//       state: "Telangana",
//       pincode: "500090",
//     },
//     id: '18ee729d7b5-432aff0f-3350-4f6e-99fb-24e22b6ebe2f',
//     tenant: 'parimitha',
//     pageId: '475'
//   },
// ];



// *production*//

export const locationJson = [
  {
    name: "kompally",
    address: {
      street: "Kompally",
      area: "Secunderabad",
      city: "Hyderabad",
      state: "Telangana",
      pincode: "500055",
    },
    id: `${process.env.REACT_APP_KOMPALLY_ORGID}`,
    tenant: 'parimitha',
    pageId: `${process.env.REACT_APP_KOMPALLY_PAGEID}`
  },

  {
    name: "chintal",
    address: {
      street: "APHB colony",
      area: "chintal",
      city: "Hyderabad",
      state: "Telangana",
      pincode: "500054",
    },
    id: `${process.env.REACT_APP_CHINTAL_ORGID}`,
    tenant: 'parimitha',
    pageId: `${process.env.REACT_APP_CHINTAL_PAGEID}`
  },
  {
    name: "medipally",
    address: {
      street: "Peerzadiguda",
      area: "Medipally",
      city: "Hyderabad",
      state: "Telangana",
      pincode: "500039",
    },
    id: `${process.env.REACT_APP_MEDIPALLY_ORGID}`,
    tenant: 'parimitha',
    pageId: `${process.env.REACT_APP_MEDIPALLY_PAGEID}`
  },
];
