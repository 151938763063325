import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowForward, Close, LocationOnOutlined, MyLocationOutlined } from "@mui/icons-material";
import { FiHome } from "react-icons/fi";
import Logo from '../../assets/hospitalimage.jpg';
import { selectedBranchInfo } from "../../redux/slices/SelectedBranchSlice";
import { branchInfo } from "../../redux/slices/branchSlice";
import ContainedButton, { OutlinedButton } from "../atoms/commonbutton";
import { setOpenBranch } from "../../redux/actions/actions";

const NavChooseBranches = ({ handleClose, openBranch, branchList, selectedbranch, handleOnNearestLocation, loading, setBranch, branch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedLocationName, setSelectedLocationName] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



  const handleSelectLocation = (location, index) => {
    setSelectedLocationName(location?.name);
    setBranch(location?.name); // Set the selected index
    localStorage.setItem("selectOrg", location?.name); // Store the selected index in localStorage
    localStorage.setItem("brName", location?.name);
    localStorage.setItem("selectedbranch", JSON.stringify(location));
    dispatch(branchInfo(location?.name));
    dispatch(selectedBranchInfo(location));
    handleClose();
    navigate('/');
  };

  return (
    <div className="px-8 py-5">
      <div>Select Location</div>
      <Grid container spacing={isMobile ? 1 : 3} alignItems={'center'}>
        <Grid item xs={12} sm={4} md={4.5} lg={2.5}>
          <div className="flex  items-center gap-3" style={{ borderBottom: '2px solid #E44190' }}>
            <LocationOnOutlined sx={{ color: "#E44190" }} />
            <Typography sx={{ fontSize: { md: 14, lg: 16 } }}>Hyderabad, Telangana, India</Typography>
          </div>
        </Grid>
        <Grid item xs={1} sm={4} md={2.5} lg={6}></Grid>
        <Grid item xs={12} sm={2} md={5} lg={3.5}>
          <div className="flex items-center gap-2">
            <ContainedButton
              bgcolor="#E44190"
              borderRadius="7px"
              text="Home"
              startIcon={<FiHome />}
              onClick={() => {
                setBranch(null); // Deselect when navigating home
                localStorage.removeItem("selectOrg"); // Remove selected index from localStorage
                localStorage.removeItem("brName");
                localStorage.removeItem("selectedbranch");
                dispatch(branchInfo(''));
                dispatch(selectedBranchInfo({}));
                navigate('/');
              }}
            />
            <OutlinedButton
              text="Nearest to Me"
              borderColor="#707070"
              color="black"
              borderRadius="7px"
              disabled
              fontSize={{ xs: 14, sm: 16, md: 16 }}
              height="40px"
              startIcon={loading ? <CircularProgress size={24} /> : <MyLocationOutlined />}
              onClick={handleOnNearestLocation}
            />
          </div>
        </Grid>
      </Grid>

      <div className="mt-2">
        <Grid container columnSpacing={4} rowSpacing={3}>
          {branchList.length > 0 ? branchList?.map((location, index) => (
            <Grid item md={6} key={index}>
              <Grid
                item
                container
                alignItems={'center'}
                sx={{
                  border: '1px solid #707070',
                  borderRadius: "7px",
                  cursor: 'pointer',
                  padding: "10px",
                  backgroundColor: branch === location?.name ? '#fce4ec' : 'transparent', // Pink background for selected item
                }}
                onClick={() => handleSelectLocation(location, index)}
              >
                <Grid item md={1.7} lg={1}>
                  <div
                    style={{
                      width: "44px",
                      height: "44px",
                      border: "1px solid #fff",
                      borderRadius: "6px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={location.orgImg || Logo}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "top" // Ensures the top part of the image is centered, cutting off the bottom
                      }}
                      alt="hospital"
                    />
                  </div>
                </Grid>
                <Grid item md={9.3} lg={10}>
                  <div>
                    <Typography sx={{ fontWeight: 500, fontSize: { xs: 14, sm: 16, md: 16 }, textTransform: 'capitalize' }}>
                      {location?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: 12, sm: 13, md: 13, lg: 13 },
                        color: '#707070',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        lineHeight: '1.2em',
                        height: '2.4em',
                      }}
                    >
                      {location?.address?.[0]?.line?.[0] || location?.address?.district || location?.address?.state || location?.address?.postalCode
                        ? [location?.address?.[0]?.line?.[0], location?.address?.district, location?.address?.state, location?.address?.postalCode]
                          .filter(Boolean)
                          .join(', ')
                        : 'Address not available'}
                    </Typography>
                  </div>
                </Grid>
                <Grid item md={1} lg={1}>
                  <ArrowForward sx={{ fontSize: "35px", color: branch === location?.name ? '#ff4081' : '#0007b7' }} />
                </Grid>
              </Grid>
            </Grid>
          )) : <Grid item md={12} textAlign={'center'} mt={8} >
            <Typography variant="h6">
              No Data Available
            </Typography>
          </Grid>
          }
        </Grid>
      </div>

      <div className="text-center mt-3">
        <ContainedButton
          text="Close"
          bgcolor="#E44190"
          borderRadius="20px"
          fontSize={{ xs: 14, sm: 16, md: 16 }}
          startIcon={<Close />}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default NavChooseBranches;
