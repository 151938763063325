import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardMedia, Divider, Alert, Grid, Paper, Stack, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import maleDoctorImg from '../../assets/images/MaleDoctor-no-photo.svg';
import FemaleDoctorImg from '../../assets/images/FemaleDoctor-No Photo.svg';
import StethIcon from '../../assets/images/stethoscope.svg';
import teleIcon from '../../assets/images/Telemedicine.svg';
import followIcon from '../../assets/images/Follow.svg';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Overview from './Overview';
import YouTubeIcon from '../../assets/images/Icon fa-brands-youtube.svg'
import InstagramIcon from '../../assets/images/Icon corebrands-instagram.svg'
import TwiterIcon from '../../assets/images/Icon fa-brands-x-twitter.svg'
import LinkedInIcon from '../../assets/images/Icon corebrands-linkedin.svg'
import RequestIcon from '../../assets/images/referral.svg'
import contactIcon from '../../assets/images/contact-card-link.svg'
import hprIcon from '../../assets/images/hpr.svg'
import { MdContactPhone } from "react-icons/md";
import { BiSolidPhone } from "react-icons/bi";
import { CiMail } from "react-icons/ci";
import { ShareOutlined } from '@mui/icons-material';
import CommunicationImg from '../../assets/images/Communication-language.svg';
// import MedicalService from './Medicalservice';
import { API_FHIR } from '../../services/ApiService';
import MedicalService from './Medicalservice';
import QrCode from '../../assets/qr.png';
import SheduleBooking from './SheduleBooking';
import './doctor.css'
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import PostPublications from './PostPublications';
import URL from '../../services/api_url';
import axios from 'axios';
import AppoinmentPopup from './appointmentPopup';
import DocProfileHeader from './docProfileHeader';
import BranchUrl from '../../services/api_branch_url';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3, overflowY: 'scroll', height: 'calc(100% - 480px)', paddingLeft: '1px', paddingRight: '1px' }} className="doctorprofiletab">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const DoctorProfile = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [userData, setUserData] = React.useState({});
    const [isshedulePage, setIsShedulePage] = React.useState(false);
    const [practitionerrole, setPractitionerrole] = useState('')
    const [doctorData, setDoctorData] = useState('')
    const { id } = useParams()
    const urls = BranchUrl()
    const [facility, setFacility] = useState(false)
    const [openSnack, setOpenSnack] = React.useState(false);
    const branchName = useSelector((state) => state.branch.branch);
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [showStickyHeader, setShowStickyHeader] = useState(false);


    // console.log(userData, "userData")
    // console.log(practitionerrole, "practitioner1234567")
    // console.log(doctorData, "doctor1234567")
    const schedule = location?.state?.schedule
    const dispatch = useDispatch()
    useEffect(() => {
        const handleScroll = () => {
            // Check if the scroll position is greater than or equal to 48vh
            // console.log(window.scrollY >= window.innerHeight * 0.48)
            if (window?.scrollY >= window?.innerHeight * 0.48) {
                setShowStickyHeader(true);
            } else {
                setShowStickyHeader(false);
            }
        };
        // Add the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        if (schedule === "open") {
            setIsShedulePage(true)
        }
    }, [schedule])
    useEffect(() => {
        axios.get(`${urls.fhir_tenant_orgId}Practitioner/${id}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'Realm': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`
            }
        }).then((res) => {
            setUserData(res?.data)
        }).catch((err) => console.log(err))

        axios.get(`${urls.fhir_tenant_orgId}PractitionerRole?practitioner=${id}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`,
                'Realm': 'parimitha'
            }
        }).then((res) => {
            // setUserData(res?.data)
            setPractitionerrole(res?.data?.entry?.[0])

        }).catch((err) => console.log(err))
    }, [])
    // console.log(practitionerrole, "practitionerrole")
    // useEffect(() => {
    //     axios.get(`${urls.fhir_auth}Location?organization=${selectBranchInfo?.id}`, {
    //         headers: {
    //             'X-FHIR-TENANT-ID': 'parimitha' || 'parimitha',
    //             'Realm': 'parimitha' || 'parimitha',
    //             'X-FHIR-DSID': `fhir${selectBranchInfo?.tenant || 'parimitha'}`
    //         }
    //     }).then((res) => {
    //         // console.log(res?.data?.entry)

    //     }).catch((err) => console.log(err))
    // }, [selectBranchInfo])
    useEffect(() => {
        if (userData && userData?.identifier?.[0]?.value) {
            axios.get(`${URL.docstethDocterData}/${userData?.identifier?.[0]?.value}`).then((res) => {
                // console.log(res?.data?.entry)
                setDoctorData(res?.data)

            }).catch((err) => console.log(err))
        }
    }, [practitionerrole])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const appointmentBooking = () => {
        if (selectBranchInfo?.name) {
            // setIsShedulePage(true)
            setOpen(true)
        } else {
            // setOpenSnack(true);
            dispatch({
                type: 'SELECT_FACILITY_BRANCH',
                payload: true
            })

        }
    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const schedulePcgFn = () => {
        setIsShedulePage((prev) => !prev)
    };

    return (
        <Box sx={{ p: { xs: '0px 0px', sm: '0px 0px', md: '0px 70px' } }}>
            {/* {openSnack &&
                <Alert variant="filled" severity="error" onClose={() => setOpenSnack(false)}>
                    Please Select Branch.
                </Alert>} */}
            <Grid container className="doctorBg">
            </Grid>


            {/* <Box sx={{ position: 'sticky', top: '115px' }}> */}
            {/* {showStickyHeader &&
                    <DocProfileHeader userData={userData} practitionerrole={practitionerrole} />
                } */}
            {/* </Box> */}
            <Grid className='px-2 md:px-10 py-5' >
                <Grid container spacing={2} >
                    <Grid item xs={12} md={8} >
                        <Box style={{ marginTop: -130, }}>
                            <DocProfileHeader userData={userData} practitionerrole={practitionerrole} doctorData={doctorData} selectedbranch={selectBranchInfo} />
                            {/* 
                             {isshedulePage ?
                                <SheduleBooking backpage={schedulePcgFn} userData={userData} practitioner={practitionerrole} />
                                : */}

                            {/* }  */}
                        </Box>
                    </Grid>
                    {!isshedulePage &&
                        <Grid item xs={12} md={4}>
                            <Paper className='p-2'>
                                <div className='text-[16px] font-medium'>Need a Consultation {userData?.name?.[0]?.text} ?</div>
                                <div className='text-[14px] font-medium pt-3'>Register & Book Appointment to start your wellness journey</div>
                                <Grid container justifyContent={"space-between"} spacing={2} pt={3}>
                                    <Grid item>
                                        <ContainedButton
                                            variant="outlined"
                                            onClick={appointmentBooking}
                                            startIcon={<img src={RequestIcon} width={'18px'} />}
                                            text="Request Appointment"
                                            // startIcon={<img src={teleIcon} width={16} height={16} alt="tele" />}
                                            height="30px"
                                            fontSize={{ xs: "10px", sm: "10px", md: "12px", lg: "14px", }}
                                            borderRadius="10px"
                                            bgcolor="#205072"
                                            color="#205072"

                                        />

                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            startIcon={<img src={teleIcon} width={16} height={16} alt="tele" />}
                                            sx={{
                                                height: "30px",
                                                fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px", },
                                                justifyContent: 'space-between',
                                                // padding:'5px',
                                                textTransform: "capitalize",
                                                borderRadius: "10px",
                                                borderColor: "#205072",
                                                color: "#205072",
                                                "&:hover": { borderColor: "#205072" },
                                            }}
                                        >
                                            Consult Online
                                        </Button>

                                    </Grid>
                                </Grid>
                                <Grid className='pt-4 text-[13px] font-normal'>
                                    Note : Telemedicine is online consultation preferable to patients, seeking second opinion, already examined by a qualified practitioner, and for follow-up review. Do not choose this in case of emergency and acute cases.
                                </Grid>
                            </Paper>
                            <Paper sx={{ px: 2, py: 1, mt: 4 }}>
                                <Grid className='flex gap-3 items-center text-base'> <img src={contactIcon} width={18} height={18} /> <span style={{ color: '#205072' }} className='text-[14px] font-semibold'>Connect with {userData?.name?.[0]?.text}</span> </Grid>
                                <Grid className='pl-2 pt-3 text-xs' justifyContent={"space-between"} container>
                                    <Grid item spacing={2}>
                                        <Grid className='flex gap-3 pt-2'><img src={hprIcon} style={{ width: '15px', height: '15px' }} /> <span className='text-xs'>NHPR ID </span>
                                            <span style={{ color: '#3F3FD5' }}>{userData?.identifier?.[2]?.value}</span>
                                        </Grid>
                                        <Grid className='pt-2 flex gap-3' ml={12}>
                                            <Grid>
                                                <Box style={{ width: '50px', height: '50px' }} className="border border-inherit">
                                                    <img src={QrCode} />
                                                </Box>
                                            </Grid>
                                            <Grid className='text-xs text-center'>Scan QR code in Mobile To book appointment with this Practitioner</Grid>
                                        </Grid>
                                        <Grid className='flex gap-3 pt-2' ><BiSolidPhone style={{ color: '#205072', border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} /> <span style={{ color: '#205072' }}>Phone</span>
                                            <span style={{ color: '#3F3FD5' }}>{userData?.telecom?.filter((res) => res?.system === "phone")?.[0]?.value || 'N/A'}</span>
                                        </Grid>
                                        <Grid className='flex gap-3 pt-2' ><CiMail style={{ color: '#205072', border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} /> <span style={{ color: '#205072' }}>Emai &nbsp;</span>
                                            <span style={{ color: '#3F3FD5' }}>{userData?.telecom?.filter((res) => res?.system === "email")?.[0]?.value || 'N/A'}</span>
                                        </Grid>
                                        <Grid container sx={{ display: "flex", mb: 2, }} className='pt-2 pl-7 items-center' spacing={1} >
                                            <Grid item className='text-xs'> Follow</Grid>
                                            <Grid item xs={2} sm={2} md={2} className='pl-10' ml={4}>
                                                <img src={TwiterIcon} alt="" width={12} height={12} style={{ height: "20px" }} />
                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2}>
                                                <img src={InstagramIcon} alt="" width={12} height={12} style={{ height: "20px" }} />
                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2}>
                                                <img src={YouTubeIcon} alt="" width={12} height={12} style={{ height: "20px" }} />
                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2}>
                                                <img src={LinkedInIcon} alt="" width={12} height={12} style={{ height: "20px" }} />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>

                        </Grid>
                    }

                </Grid>
            </Grid>
            <AppoinmentPopup open={open} setOpen={setOpen} selectedbranch={selectBranchInfo} userData={userData} userRole={practitionerrole} />
        </Box >
    )
}

export default DoctorProfile
