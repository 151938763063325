import React from 'react'
import CommonDialog from '../atoms/commonModal'
import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, InputAdornment, MenuItem, Paper, Popper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { OutlinedButton } from '../atoms/commonbutton'

function TelemedicinePopup({ handleClose, open, setTelemedicineOpen }) {
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)

    const handleCancel = () => {
        setTelemedicineOpen(false)
    }
    return (
        <div>
            <CommonDialog maxWidth={'sm'} onClose={handleCancel}
                open={open}
            >


                <DialogTitle sx={{ p: { xs: '0px 25px', md: "10px 40px 0px 40px" }, }}>
                    <div className='text-center text:xl md:text-2xl font-medium'>Telemedicine</div>
                    <div className='text-xs text-center font-medium text-[#E44190]'>
                        @ {selectedbranch?.name || 'Paramitha Hospitals'} </div>
                    <hr style={{ margin: '10px 0px' }} />
                </DialogTitle>
                <DialogContent>

                    < div className='p-10 text-center'>
                        Coming Soon
                    </div>


                </DialogContent >
                <Divider />
                {/* <div className='text-center'><ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div> */}
                <DialogActions sx={{ m: "5px 20px", justifyContent: 'space-end' }}>

                    <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={handleCancel} >Cancel</Button>
                    {/* <OutlinedButton text='Coming Soon' width="150px" height="32px" borderColor="#1B5775" color="#1B5775" borderRadius="7px" /> */}

                </DialogActions>




            </CommonDialog >
        </div >
    )
}

export default TelemedicinePopup