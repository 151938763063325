import { MoreVert, Public } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";

import medxpertsIcon from "../../assets/images/like-icon.svg";
import DoctorText from "./doctorPosts/doctorText";
import DoctorImage from "./doctorPosts/doctorImage";
import DoctorVideo from "./doctorPosts/doctorVideo";
import DoctorArticle from "./doctorPosts/doctorArticle";
import DoctorEvent from "./doctorPosts/doctorEvent";
import PostActions from "../home/healhinfor/PostActions";

const DoctorsPosts = ({ feedData }) => {
    const item = feedData;
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const timeSince = (date) => {
        var ms = new Date().getTime() - new Date(date).getTime();
        var seconds = Math.floor(ms / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);
        var months = Math.floor(days / 30);
        var years = Math.floor(months / 12);
        if (seconds < 1) {
            return "Just now";
        }
        if (seconds < 60) {
            return seconds + "s";
        }
        if (minutes < 60) {
            return minutes + "m";
        }
        if (hours < 24) {
            return hours + "h";
        }
        if (days < 30) {
            return days + "d";
        }
        if (months < 12) {
            return months + " month";
        } else {
            return years + "y";
        }
    };

    return (
        <div>
            <Box
                sx={{
                    // p: '10px',
                    border: "1px solid #e5e5dc",
                    borderRadius: "10px",
                    gap: 1,
                    mt: 1,
                    m: "15px",
                }}
            >
                <Grid
                    container
                    columnSpacing={2}
                    sx={{
                        p: "7px 15px",
                        height: '80px'
                    }}
                >
                    <Grid item xs={2} sm={2.3} md={1.2}>
                        {item?.pageData?.profilePic?.length > 0 ||
                            item?.userData?.profilePic?.length > 0 ? (
                            <Avatar
                                src={
                                    item.pageData?.profilePic
                                        ? item.pageData.profilePic
                                        : item.userData?.profilePic
                                }
                                sx={{
                                    borderRadius: "7px!important",
                                    // border: "1px solid lightgrey",
                                    width: { sm: 54, md: 54 },
                                    height: { sm: 54, md: 54 },
                                    p: "5px",
                                    ".MuiAvatar-img": { borderRadius: "5px" },
                                }}
                            />
                        ) : (
                            <Avatar
                                sx={{
                                    borderRadius: "7px!important",
                                    // border: "1px solid lightgrey",
                                    width: { sm: 55, md: 65 },
                                    height: { sm: 55, md: 65 },
                                }}
                                src={medxpertsIcon}
                            />
                        )}
                    </Grid>

                    <Grid item xs={8} sm={7.7} md={9.8}>
                        {item?.pageData?.fullName?.length > 0 ||
                            item?.userData?.fullName?.length > 0 ? (
                            <Typography
                                sx={{
                                    fontSize: { xs: 14, sm: 14, md: 14 },
                                    fontWeight: 500,
                                    cursor: "pointer",
                                }}
                            >
                                {(item.pageData?.fullName || item.userData?.fullName || "")
                                    .charAt(0)
                                    .toUpperCase() +
                                    (
                                        item.pageData?.fullName ||
                                        item.userData?.fullName ||
                                        ""
                                    ).slice(1)}
                            </Typography>
                        ) : (
                            <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
                        )}

                        {item?.pageData?.caption?.length > 0 ||
                            item?.userData?.caption?.length > 0 ? (
                            <Typography
                                sx={{
                                    fontSize: { xs: 12, sm: 12, md: 12 },
                                }}
                            >
                                {item.pageData?.caption || item.userData?.caption}
                            </Typography>
                        ) : null}

                        <Typography color="black">
                            <Typography
                                sx={{
                                    fontSize: { xs: 12, sm: 12, md: 12 },
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                <Public sx={{ fontSize: "14px", color: "#336ECC" }} />
                                {`${timeSince(moment.utc(item.createDate).local())}`}
                            </Typography>
                            &nbsp;
                        </Typography>
                    </Grid>

                    <Grid item xs={2} sm={2} md={1}>
                        <IconButton>
                            <MoreVert />
                        </IconButton>
                    </Grid>
                </Grid>
                <Box sx={{
                    maxHeight: 285, p: "7px 15px",
                }}>
                    {/* <Typography
            sx={{
              textOverflow: "ellipsis",
              wordWrap: "break-word",
              fontWeight: 500,
              fontSize: 14,
              p: "0px 10px",
            }}
          >
            {item?.description ? (
              <>
                {expanded
                  ? item.description
                  : `${item.description.slice(0, 114)}`}
                {item.description.length > 95 ? (
                  <Typography
                    component={"span"}
                    onClick={handleExpandClick}
                    sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                  >
                    {expanded ? "...see less" : "...see more"}{" "}
                  </Typography>
                ) : (
                  ""
                )}
              </>
            ) : (
              "Hi"
            )}
          </Typography> */}
                    {/* <CardContent> */}
                    {
                        item.postType === "TEXT" ? (
                            <DoctorText docFeed={item} />
                        ) : item.postType === "IMAGE" ? (
                            <DoctorImage docFeed={item} />
                        ) : item.postType === "VIDEO" ? (
                            <DoctorVideo docFeed={item} />
                        ) : item.postType === "ARTICLE" ? (
                            <DoctorArticle docFeed={item} />
                        ) : item.postType === "EVENT" ? (
                            <DoctorEvent docFeed={item} />
                        ) : null}
                    {/* </CardContent> */}
                </Box>
                <div className="mt-1">
                    <PostActions item={item} />
                </div>
            </Box>
        </div>
    );
};
export default DoctorsPosts;